import React, {useContext, useState} from "react"
import { Link } from "react-router-dom"
import AuthController from "../../../stories/_auth/Auth/AuthController"
import FormBuilder from "../../../class/tool/FormBuilder"
import LoaderCircle from "../../loader/LoaderCircle"
import logo from '../../../image/logo/skytill-logo-central-color.png'
import SessionContext from "../../../context/SessionContext"
import '../../../css/form/Form.css'
import '../../../css/form/FormLogin.css'

const FormLogin = () => {
    const controller = new AuthController()

    const { handleLogout } = useContext(SessionContext)
    const [ values, setValues ] = useState({
        email: "",
        password: "",
        rememberMe: false
    })
    const [ errors, setErrors ] = useState([])
    const [ saving, setSaving ] = useState(false)
    const [ globalError, setGlobalError ] = useState(null)
    const [ rows, setRows ] = useState([
        {
            label: "Email",
            attribute: "email",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Adresse email",
            emptyText: "Aucune"
        },
        {
            label: "Mot de passe",
            attribute: "password",
            inputType: "password",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Mot de passe",
            emptyText: ""
        }
    ])

    const handleRememberMe = () => {
        handleChange("rememberMe", "bool", !values.rememberMe)
    }
    const handleChange = (attribute, returnType, val, strict = false) => {
        let value = FormBuilder.buildVal(returnType, val);
        let filtered = rows.filter(row => row.attribute === attribute && row.inputType === "select" && row.returnType === "int");
        let index = value;

        if (!strict && filtered.length > 0 && filtered[0].list.length > 0) {
            value = parseInt(filtered[0].list[index].id);

            if (filtered[0].list[index].type !== undefined && values[attribute.replace("_id", "_type")] !== undefined) {
                setValues(prev => ({
                    ...prev,
                    [attribute]: value,
                    [attribute.replace("_id", "_type")]: filtered[0].list[index].type
                }));
            }
            else {
                setValues(prev => ({
                    ...prev,
                    [attribute]: value
                }));
            }
        }
        else {
            filtered = rows.filter(row => row.attribute === attribute && row.inputType === "select" && row.returnType === "string")

            if (filtered.length > 0) {
                if (filtered[0].list.filter(_ => _.value === value && _.id !== undefined && _.id === null).length > 0)
                    value = null
            }

            setValues(prev => ({
                ...prev,
                [attribute]: value
            }))
        }
    }
    const callToSave = () => {
        setGlobalError(null)
        reinitAllEdits()
        authenticate()
    }
    const reinitAllEdits = () => {
        setErrors([])
    }
    const check422Errors = errorDatas => {
        setGlobalError("Certaines données sont invalides");

        if(errorDatas !== undefined) {
            let keys = Object.keys(errorDatas);
            let fields = ["email", "password"];

            for(let item in fields)
                if(keys.includes(fields[item]))
                    defineErrors(fields[item], false);
        }
    }
    const defineErrors = (type, empty) => {
        let errorsTmp = errors.slice();

        switch (type) {
            case "name":
                if(empty) errorsTmp["name"] = "Vous devez saisir un nom";
                else errorsTmp["name"] = "Ce nom n'est pas valide";
                break;
            default: break;
        }

        setErrors(errorsTmp);
    }
    const handleReturnAuthenticate = (response, error, status) => {
        switch (status) {
            case 200:
                localStorage.setItem("token", response.data.token)
                localStorage.setItem("expires_in", addSeconds(Date.now(), response.data.expires_in).toString())
                localStorage.setItem("remember_me", values.rememberMe ? "true" : "false")
                localStorage.setItem("originEnv", JSON.stringify(response.data.environment.env))
                localStorage.setItem("env", JSON.stringify(response.data.environment.env))
                localStorage.setItem("user", JSON.stringify({}))
                localStorage.setItem("company", JSON.stringify({}))
                localStorage.setItem("store", JSON.stringify({}))
                sessionStorage.setItem("catalogs", JSON.stringify([]))
                sessionStorage.setItem("catalog", JSON.stringify({}))

                me()

                break
            case 422:
                check422Errors(error)
                setSaving(false)
                break
            case 401:
                setGlobalError("Les identifiants sont incorrects")
                setSaving(false)
                break
            default:
                setGlobalError("Une erreur s'est produite lors de l'authentification")
                setSaving(false)
                break
        }
    }
    const authenticate = () => {
        setSaving(true)

        controller._callback = handleReturnAuthenticate
        controller.authenticate(values.email, values.password)
    }
    const handleSubmit = event => {
        if (event !== undefined)
            event.preventDefault()
        callToSave()
    }
    const addSeconds = (date, seconds) => {
        return Math.floor(date / 1000) + seconds
    }
    const me = () => {
        controller._callback = handleMe
        controller.me()
    }
    const handleMe = (response, error, status) => {
        switch (status) {
            case 200:
                localStorage.setItem("user", JSON.stringify(response.data))
                window.location = "/"
                break
            case 401:
                handleLogout()
                break
            default:
                setGlobalError("Une erreur s'est produite lors de l'authentification")
                setSaving(false)
                break
        }
    }

    return (
        <form className="form formLogin" onSubmit={handleSubmit}>
            <img src={logo} alt="SkyTill Manager" className="logo"/>
            {
                globalError !== null
                && <p className="globalError">{globalError}</p>
            }
            {
                rows.map((row, index) => (
                    <div key={index} className="clearing">
                        <label className={row.classnameLabel}>{row.label}</label>
                        {
                            FormBuilder.buildInputByType(row, values, errors, handleChange, null, null, handleSubmit, null, null, null, index === 0)
                        }
                    </div>
                ))
            }
            <div className={"belowLogin"}>
                <label className="labelCheckbox">
                    <input type="checkbox" id="rememberMe" checked={values.rememberMe} value={values.rememberMe} onChange={handleRememberMe} />
                    <span className={"checkmarkRememberMe"} />
                    <p>Se souvenir de moi</p>
                </label>
                <Link className="forgot" to="/forgot">Mot de passe oublié ?</Link>
            </div>
            <button className={"submit " + (saving ? "hide" : "")}>
                {
                    saving
                        ? "Connexion en cours..."
                        : "Connexion"
                }
                <LoaderCircle display="loader submitForm " hide={!saving ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF"/>
            </button>
        </form>
    )
}

export default FormLogin
