import Rest from "../../../class/tool/Rest"
import SubCategory from "./SubCategory"
import Product from "../Products/Product"
import FormBuilder from "../../../class/tool/FormBuilder";

class SubCategoryController {
    #_rest = new Rest()
    _callback = null
    _baseURL = "catalogs/{idCatalog}/subcategories"
    _baseURLWithoutCatalog = "subcategories"

    static checkName(val) {
        //const regxp = /^[\w&-_. ]{2,50}$/g

        if(val === "") throw new Error("EMPTY")
        //if (!regxp.test(val.removeDiacritics())) throw new Error("WRONG")

        return true
    }
    static checkCategory(val) {
        const regxp = /^[0-9]+$/g

        if (val !== "" && !regxp.test(val)) throw new Error("WRONG")

        return true
    }
    static checkVatRate(val) {
        const regxp = /^[0-9]+$/g

        if (val !== "" && !regxp.test(val)) throw new Error("WRONG")

        return true
    }
    static checkColor(val) {
        const regxp = /^\#[a-fA-F0-9]{3,8$/g

        if (val !== "" && !regxp.test(val)) throw new Error("WRONG")

        return true
    }
    static checkReference(val) {
        const regxp = /^[a-zA-Z0-9-_+#\/\\]{2,}$/g

        if (val !== "" && !regxp.test(val.removeDiacritics())) throw new Error("WRONG")

        return true
    }

    setFormValues(object, setValues) {
        let defaultCatalogId = object.catalog_id

        if (sessionStorage.getItem("defaultCatalogIdInForm") !== null) {
            defaultCatalogId = parseInt(sessionStorage.getItem("defaultCatalogIdInForm"))
        }
        else {
            const catalog = JSON.parse(sessionStorage.getItem("catalog"))

            if (Object.keys(catalog).length > 0)
                defaultCatalogId = parseInt(catalog.id)
        }

        setValues(prev => ({
            ...prev,
            name: object.name,
            reference: object.reference,
            catalog_id: defaultCatalogId,
            category_id: object.category_id,
            vat_rate_id: object.vat_rate_id !== null ? object.vat_rate_id : (object.vatRules.length > 0 ? (object.vatRules[0].vat_rate_id !== undefined ? object.vatRules[0].vat_rate_id : null) : null),
            color: object.color
        }))
    }
    returnUpdatesFromCompare(object, datas, compare = true) {
        return FormBuilder.returnUpdates(object, datas, compare)
    }
    checkValues(object) {
        const params = [
            { name: "name", method: "checkName", val: object.name },
            { name: "label", method: "checkLabel", val: object.label },
            { name: "color", method: "checkColor", val: object.color },
            { name: "reference", method: "checkReference", val: object.reference },
            { name: "referenceProvider", method: "checkReferenceProvider", val: object.referenceProvider },
            { name: "provider", method: "checkProvider", val: object.provider_id },
            { name: "brand", method: "checkBrand", val: object.brand_id },
            { name: "category", method: "checkCategory", val: { id: object.category_id, type: object.category_type } },
            { name: "description", method: "checkDescription", val: object.description },
            { name: "nullStockTrigger", method: "checkNullStockTrigger", val: object.nullStockTrigger },
            { name: "printDescription", method: "checkPrintDescription", val: object.printDescription },
            { name: "soldOut", method: "checkSoldOut", val: object.soldOut },
            { name: "stockManagement", method: "checkStockManagement", val: object.stockManagement },
            { name: "stockThresholdTrigger", method: "checkStockThresholdTrigger", val: object.stockThresholdTrigger },
            { name: "unit", method: "checkUnit", val: object.unit },
            { name: "letiablePrice", method: "checkletiablePrice", val: object.letiablePrice }
        ]
        let errors = {}
        let method

        for (let i in params) {
            method = SubCategoryController[params[i].method]

            try { method.apply(null, params[i].val) }
            catch (e) { errors.params[i].name = e.message }
        }

        if (Object.keys(errors).length > 0) throw new Error(JSON.stringify(errors))
        else return true
    }

    index(idCatalog = null, input = "", page = 1, nb = 25, sortingName = "", sortingValue = "") {
        let catalog = JSON.parse(sessionStorage.getItem("catalog"));
        let url = "";
        let params = "";
        if (page > 0) params += (params.length === 0 ? "?" : "&") + "page=" + page + "&nb=" + nb;
        if (input.length > 0) params += (params.length === 0 ? "?" : "&") + "input=" + input;
        if (sortingName.length > 0) params += (params.length === 0 ? "?" : "&") + "sorton=" + sortingName + "&sortvalue=" + sortingValue;

        if (catalog !== null && Object.keys(catalog).length > 0)
            url = '/' + this._baseURL.replace("{idCatalog}", catalog.id) + params;
        else if (idCatalog !== undefined && idCatalog !== null)
            url = '/' + this._baseURL.replace("{idCatalog}", idCatalog) + params;
        else
            url = '/' + this._baseURLWithoutCatalog + params;

        this.#_rest.sendQuery(
            'GET',
            url,
            {},
            this.handleIndex);
    }
    handleIndex = (response, error, status = 200) => {
        let listObjects = []
        let pagination = null

        if(status === 200 && response.data !== undefined && response.data.data !== undefined) {
            if(response.data.total !== undefined) {
                pagination = {
                    total: response.data.total,
                    from: response.data.from != null ? response.data.from : 0,
                    to: response.data.to != null ? response.data.to : 0,
                    per_page: response.data.per_page,
                    current_page: response.data.current_page,
                    first_page_url: new URL(response.data.first_page_url).search,
                    last_page_url: new URL(response.data.last_page_url).search,
                    next_page_url: response.data.next_page_url !== null ? new URL(response.data.next_page_url).search : null,
                    prev_page_url: response.data.prev_page_url !== null ? new URL(response.data.prev_page_url).search : null
                }
            }

            const data = response.data.data

            for(let item in data)
                listObjects.push( new SubCategory(data[item]))
        }

        this._callback(listObjects, error, pagination, status)
    }

    show(idCatalog, id) {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL.replace("{idCatalog}", idCatalog) + '/' + id,
            {},
            this.handleShow)
    }
    handleShow = (response, error, status = 200) => {
        const object = !error ? new SubCategory(response.data) : null
        this._callback(object, error, status)
    }

    post(idCatalog, datas) {
        this.#_rest.sendQuery(
            'POST',
            '/' + this._baseURL.replace("{idCatalog}", idCatalog),
            datas,
            this.handlePost)
    }
    handlePost = (response, error, status) => {
        this._callback(response, error, status)
    }

    put(idCatalog, object, datas) {
        this.#_rest.sendQuery(
            'PUT',
            '/' + this._baseURL.replace("{idCatalog}", idCatalog) + '/' + object.id,
            datas,
            this.handlePut)
    }
    handlePut = (response, error, status) => {
        this._callback(response, error, status)
    }

    delete(object) {
        this.#_rest.sendQuery(
            'DELETE',
            '/' + this._baseURL.replace("{idCatalog}", object.catalog_id) + '/' + object.id,
            {},
            this.handleDelete)
    }
    handleDelete = (response, error, status) => {
        this._callback(response, error, status)
    }

    histo(idCatalog) {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL.replace("{idCatalog}", idCatalog) + '/histos',
            {},
            this.handleHisto)
    }
    handleHisto = (response, error, status = 200) => {
        let listObjects = []

        if(status === 200) {
            let data = response.data

            for(let item in data)
                listObjects.push(data[item])
        }

        this._callback(listObjects, error, status)
    }

    histoTarget(idCatalog, id) {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL.replace("{idCatalog}", idCatalog) + '/' + id + '/histos',
            {},
            this.handleHistoTarget)
    }
    handleHistoTarget = (response, error, status = 200) => {
        let listObjects = []

        if(status === 200) {
            let data = response.data

            for(let item in data)
                listObjects.push(data[item])
        }

        this._callback(listObjects, error, status)
    }

    // Products

    handleIndexProducts = (response, error, status = 200) => {
        let listObjects = []
        let pagination = null

        if(status === 200) {
            let data

            if (response.data !== undefined && response.data.data !== undefined) {
                if(response.data.total !== undefined) {
                    pagination = {
                        total: response.data.total,
                        from: response.data.from != null ? response.data.from : 0,
                        to: response.data.to != null ? response.data.to : 0,
                        per_page: response.data.per_page,
                        current_page: response.data.current_page,
                        first_page_url: new URL(response.data.first_page_url).search,
                        last_page_url: new URL(response.data.last_page_url).search,
                        next_page_url: response.data.next_page_url !== null ? new URL(response.data.next_page_url).search : null,
                        prev_page_url: response.data.prev_page_url !== null ? new URL(response.data.prev_page_url).search : null
                    }
                }

                data = response.data.data
            }
            else if (response.data)
                data = response.data

            for(let item in data)
                listObjects.push(new Product(data[item]))
        }

        this._callback(listObjects, error, pagination, status)
    }
    indexProducts(idCatalog, idSubCategory, input = "", page = 1, nb = 25, sortingName = "", sortingValue = "") {
        let params = "";
        if (page > 0) params += (params.length === 0 ? "?" : "&") + "page=" + page + "&nb=" + nb;
        if (input.length > 0) params += (params.length === 0 ? "?" : "&") + "input=" + input;
        if (sortingName.length > 0) params += (params.length === 0 ? "?" : "&") + "sorton=" + sortingName + "&sortvalue=" + sortingValue;

        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL.replace("{idCatalog}", idCatalog) + '/' + idSubCategory + '/products' + params,
            {},
            this.handleIndexProducts)
    }
}

export default SubCategoryController
