import Entity from "../../../class/abstract/Entity"

class Catalog extends Entity {
    name = null
    owner_id = null
    owner_type = null
    color = null
    planned_deployments_from = []
    planned_deployments_to = []
    deployments = []
    targeted_deployments = []
    children = []
    stores = []
    last_histo_author = null;

    constructor(datas = {}) {
        super(datas)
        this.setAttributes(datas)
    }

    setAttributes(datas) {
        let keys = Object.keys(this)
        let key = ""

        for(let i in keys) {
            key = keys[i]

            if (datas[key] !== undefined)
                Reflect.set(this, key, datas[key])
        }

        if (this.children.length > 0) {
            const env = JSON.parse(localStorage.getItem("env"))
            let indexFound = -1

            for (let i in this.children) {
                if (this.children[i].owner_type.toLowerCase().includes("store") || this.children[i].owner_type.toLowerCase().includes("stores")) {
                    indexFound = env.stores.findIndex(_ => _.id === this.children[i].owner_id)
                    if (indexFound < 0) continue

                    this.stores.push(env.stores[indexFound].shortName)
                }
            }
        }
    }
}

export default Catalog
