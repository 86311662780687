import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import LoaderCircle from "../../loader/LoaderCircle";
import FormBuilder from "../../../class/tool/FormBuilder";
import CategoryController from "../../../stories/_catalog/Categories/CategoryController";
import CatalogController from "../../../stories/_catalog/Catalogs/CatalogController";
import VatRateController from "../../../stories/_catalog/VatRates/VatRateController";
import '../../../css/form/Form.css';
import Color from "../../../class/tool/Color";

const FormStart = props => {
    const item = "categories";
    const controller = new CategoryController();
    const env = JSON.parse(localStorage.getItem("env"));

    const { list, object, handleIndex } = props;
    const [ reload, setReload ] = useState(false);
    const [ loadingCatalogs, setLoadingCatalogs ] = useState(true);
    const [ loadingVatRates, setLoadingVatRates ] = useState(true);
    const [ catalogs, setCatalogs ] = useState([
        {
            value: "Choisir un catalogue",
            id: null
        }, {
            value: "----------",
            id: null
        }
    ]);
    const [ vatRates, setVatRates ] = useState([
        {
            value: "Choisir une TVA",
            id: null
        }, {
            value: "----------",
            id: null
        }
    ]);
    const [ values, setValues ] = useState({});
    const [ errors, setErrors ] = useState([]);
    const [ saving, setSaving ] = useState(false);
    const [ globalError, setGlobalError ] = useState(null);
    const [ rows, setRows ] = useState([
        {
            label: "Nom",
            attribute: "name",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Nom",
            emptyText: "Aucun",
            focus: true
        },
        {
            label: "TVA",
            attribute: "vat_rate_id",
            inputType: "select",
            returnType: "int",
            list: [],
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucune"
        },
        {
            label: "Réference",
            attribute: "reference",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Réference",
            emptyText: "Aucune"
        },
        {
            label: "Couleur",
            attribute: "color",
            inputType: "color",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucune"
        }
    ]);

    const getCatalogs = () => {
        const controller = new CatalogController()
        controller._callback = handleGetCatalogs
        controller.index("", 0, 0, true, "name", "asc")
    }
    const handleGetCatalogs = (list, error, pagination, status) => {
        switch (status) {
            case 200:
                sessionStorage.setItem("catalogs", JSON.stringify(list))

                if (list.length === 1)
                    sessionStorage.setItem("catalog", JSON.stringify(list[0]))
                else
                    sessionStorage.setItem("catalog", JSON.stringify({}))

                let listTmp = catalogs.slice()

                for (let i in list) {
                    listTmp.push({
                        value: list[i].name,
                        id: list[i].id,
                        readonly: list[i].planned_deployments_to.length > 0
                    })
                }

                setCatalogs(listTmp)
                setLoadingCatalogs(false)
                break
            default:
                setGlobalError("Impossible de récupérer les catalogues")
                break
        }
    }
    const getVatRates = () => {
        const controller = new VatRateController()
        controller._callback = handleGetVatRates
        controller.index()
    }
    const handleGetVatRates = (list, error, status) => {
        switch (status) {
            case 200:
                let vatRatesTmp = vatRates.slice()

                for (let item in list) {
                    vatRatesTmp.push({
                        value: list[item].name,
                        id: list[item].id
                    })
                }

                setVatRates(vatRatesTmp)
                break
            default:
                setGlobalError("Impossible de récupérer les TVA")
                break
        }

        setLoadingVatRates(false)
    }
    const initValues = () => {
        controller.setFormValues(object, setValues)
    }
    const initRows = () => {
        let rowsTmp = rows.slice()

        rowsTmp[1].list = vatRates

        if (catalogs.length > 3) {
            rowsTmp.splice(1, 0, {
                label: "Catalogue",
                attribute: "catalog_id",
                inputType: "select",
                returnType: "int",
                list: catalogs,
                dictionary: null,
                classnameLabel: "label",
                classnameInput: "",
                classnameNoInput: "",
                placeholder: "",
                emptyText: "Aucun"
            })
        }

        setRows(rowsTmp)
    }
    const handleChange = (attribute, returnType, val, strict = false) => {
        FormBuilder.handleChange(rows, setValues, attribute, returnType, val, strict)
    }
    const callToSave = () => {
        setGlobalError(null)
        reinitAllEdits()
        save()
    }
    const reinitAllEdits = () => {
        setErrors([])
    }
    const returnUpdates = () => {
        return controller.returnUpdatesFromCompare(object, values);
    }
    const check422Errors = errorDatas => {
        setGlobalError("Certaines données sont invalides")

        if(errorDatas !== undefined) {
            let keys = Object.keys(errorDatas)
            let fields = []

            for(let item in fields)
                if(keys.includes(fields[item]))
                    defineErrors(fields[item], false)
        }
    }
    const defineErrors = (type, empty) => {
        let errorsTmp = errors.slice()

        switch (type) {
            case "name":
                if(empty) errorsTmp["name"] = "Vous devez saisir un nom"
                else errorsTmp["name"] = "Ce nom n'est pas valide"
                break
            default: break
        }

        setErrors(errorsTmp);
    }
    const save = () => {
        const catalog = JSON.parse(sessionStorage.getItem("catalog"))
        const datas = returnUpdates()

        if (Object.keys(datas).length === 0) return

        if (datas.color === undefined) {
            datas.color = defineAutoColor();
        }

        if (datas.vat_rate_id !== undefined && datas.vat_rate_id !== null) {
            const catalogs = JSON.parse(sessionStorage.getItem("catalogs"))
            const catalog = catalogs.find(_ => _.id === datas.catalog_id)

            datas.vats = {}

            switch (env.type) {
                case "company":
                    for (let i in catalog.stores)
                        datas.vats[env.stores.find(_ => _.shortName === catalog.stores[i]).id] = datas.vat_rate_id

                    break
                case "store":
                    datas.vats[env.id] = datas.vat_rate_id
                    break
                default: break
            }

            delete datas.vat_rate_id
        }

        setSaving(true)

        controller._callback = returnSave
        controller.post(Object.keys(catalog).length > 0 ? catalog.id : values.catalog_id, datas)
    }
    const returnSave = (response, error, status) => {
        setSaving(false)

        switch (status) {
            case 201:
                if (values.catalog_id !== undefined && values.catalog_id !== null) // Enregistre pour les futurs création la pré-sélection du catalogue
                    sessionStorage.setItem("defaultCatalogIdInForm", values.catalog_id)

                setReload(true)
                handleIndex()
                break
            case 422:
                check422Errors(error)
                break
            default:
                setGlobalError("Une erreur s'est produite lors de l'enregistrement de la catégorie")
                break
        }
    }
    const handleSubmit = event => {
        if (event !== undefined)
            event.preventDefault()
        callToSave()
    }
    const defineAutoColor = () => {
        let index;

        for (let i in Color.defaultColors) {
            index = list.findIndex(_ => _.color === Color.defaultColors[i]);

            if (index < 0)
                return Color.defaultColors[i];
        }

        return Color.defaultColors[Math.floor(Math.random() * Color.defaultColors.length)];
    }

    useEffect(() => {
        getCatalogs()
        getVatRates()
        initValues()
    }, [])
    useEffect(() => {
        if (!loadingCatalogs && !loadingVatRates)
            initRows()
    }, [loadingCatalogs, loadingVatRates])

    return (
        <div className="clearing">
            {
                reload && <Redirect to={ "/" + item } />
            }
            {
                (loadingVatRates || loadingCatalogs)
                    ? <LoaderCircle display="loader logWait" hide="" strokeWidth="5" />
                    : <form className="form" onSubmit={handleSubmit}>
                        {
                            globalError !== null && <p className="globalError">{globalError}</p>
                        }
                        {
                            rows.map((row, index) => (
                                <div key={ index } className="clearing">
                                    <label className={ row.classnameLabel }>{ row.label }</label>
                                    {
                                        FormBuilder.buildInputByType(row, values, errors, handleChange, null, null, handleSubmit, null, null, null, index === 0)
                                    }
                                </div>
                            ))
                        }
                        <button className={"submit " + (saving ? "hide" : "")}>
                            {
                                saving
                                    ? "Enregistrement..."
                                    : "Enregistrer"
                            }
                            <LoaderCircle display="loader submitForm " hide={!saving ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF" />
                        </button>
                        <Link to={ "/" + item }>
                            <button className="cancel align">Fermer</button>
                        </Link>
                        <div className="clearing" />
                    </form>
            }
        </div>
    )
}

export default FormStart;
