import Rest from "../../../class/tool/Rest"
import Pricelist from "./Pricelist"
import stat from "../../../component/page/content/analyze/Stat";

class PricelistController {
    #_rest = new Rest()
    _callback = null
    _baseURL = "catalogs/{idCatalog}/pricelists"
    _baseURLWithoutCatalog = "pricelists"

    // perform

    setFormValues(object, categories, products, vatrates, setValues) {
        let categoriesLines = this.getFormLinesByTypesValues("category", object, categories, products, vatrates)

        setValues(prev => ({
            ...prev,
            name: object.name,
            reference: object.reference,
            description: object.description,
            color: object.color,
            main: object.main,
            categoriesLines: categoriesLines
        }))
    }
    getFormLinesByTypesValues(type, object, categories, products, vatrates) {
        if (categories === null) return []

        const env = JSON.parse(localStorage.getItem("env"))
        const catalogs = JSON.parse(sessionStorage.getItem("catalogs"))
        const loop = type === "category" ? categories.slice() : products.slice()
        let lines = []
        let idStores = []
        let line = {}
        let idStore = null
        let category

        if (env.type === "company") {
            let catalog = catalogs[catalogs.findIndex(_ => _.id === object.catalog_id)]

            for (let i in catalog.stores)
                idStores.push(env.stores[env.stores.findIndex(_ => _.shortName === catalog.stores[i])].id)
        }
        else
            idStores.push(env.id)

        for (let key in loop) {
            line = {}
            line.object = loop[key]
            line.toUpdate = {}
            line.categoryPriceRule = {}
            line.categoryVatRule = {}
            line.categoryVatRate = {}

            switch (type) {
                case "category":
                    category = line.object
                    break
                case "product":
                    // eslint-disable-next-line no-loop-func
                    category = products[products.findIndex(_ => _.category_type.toLocaleLowerCase() === "categories" && _.category_id === line.object.category_id)]

                    line.productVat = {}
                    line.productVatRate = {}
                    line.productPriceVariation = {}
                    line.productPrice = {}
                    break
                default: break
            }

            for (let indexStore in idStores) {
                idStore = idStores[indexStore]

                line.toUpdate[idStore] = false
                line.categoryPriceRule[idStore] = []
                line.categoryVatRule[idStore] = []
                line.categoryVatRate[idStore] = []

                for (let i in category.priceRules) {
                    if (category.priceRules[i].store_id === null || category.priceRules[i].store_id === idStore) {
                        line.categoryPriceRule[idStore].push(Object.assign({}, category.priceRules[i]))
                    }
                }

                for (let i in category.vatRules) {
                    if (category.vatRules[i].store_id === null || category.vatRules[i].store_id === idStore) {
                        line.categoryVatRule[idStore].push(Object.assign({}, category.vatRules[i]))

                        // eslint-disable-next-line no-loop-func
                        let categoryVatRateFound = vatrates.filter(_ => _.id === line.categoryVatRule[idStore].vat_rate_id)

                        if (categoryVatRateFound.length > 0)
                            line.categoryVatRate[idStore].push(categoryVatRateFound)
                    }
                }

                if (type === "product") {
                    line.productVat[idStore] = null
                    line.productVatRate[idStore] = null
                    line.productPriceVariation[idStore] = null
                    line.productPrice[idStore] = null

                    for (let i in object.vats) {
                        if ((object.vats[i].store_id === null || object.vats[i].store_id === idStore)) {
                            line.productVat[idStore].push(Object.assign({}, object.vats[i]))

                            // eslint-disable-next-line no-loop-func
                            let productVatRateFound = vatrates.filter(_ => _.id === line.productVat[idStore].vat_rate_id)

                            if (productVatRateFound.length > 0)
                                line.productVatRate[idStore].push(productVatRateFound)
                        }
                    }

                    for (let i in object.price_variations) {
                        if (object.price_variations[i].store_id === null || object.price_variations[i].store_id === idStore) {
                            line.productPriceVariation[idStore].push(Object.assign({}, object.price_variations[i]))
                        }
                    }

                    for (let i in object.prices) {
                        if (object.prices[i].store_id === null || object.prices[i].store_id === idStore) {
                            line.productPrice[idStore].push(Object.assign({}, object.prices[i]))
                        }
                    }
                }
            }

            lines.push(line)
        }

        return lines
    }
    returnUpdatesFromCompare(object, datas) {
        let dataKeys = Object.keys(datas)
        let key = ""
        let foundUpdate = false
        let updates = {}

        for(let i in dataKeys) {
            key = dataKeys[i]

            if (datas[key] !== undefined) {
                if (key === "categoriesLines") {
                    for (let i in datas[key]) {
                        for (let keyStore in datas[key][i].toUpdate) {
                            if (datas[key][i].toUpdate[keyStore]) {
                                Reflect.set(updates, key, datas[key])
                                foundUpdate = true
                                break
                            }
                        }

                        if (foundUpdate)
                            break
                    }
                }
                else if (Reflect.get(object, key) !== datas[key] && datas[key] !== null && (datas[key].length > 0 || datas[key] >= 0))
                    Reflect.set(updates, key, datas[key])
            }
        }

        return updates
    }
    checkValues(object) {
        const params = [
            { name: "name", method: "checkName", val: object.name },
            { name: "label", method: "checkLabel", val: object.label },
            { name: "color", method: "checkColor", val: object.color },
            { name: "reference", method: "checkReference", val: object.reference },
            { name: "referenceProvider", method: "checkReferenceProvider", val: object.referenceProvider },
            { name: "provider", method: "checkProvider", val: object.provider_id },
            { name: "brand", method: "checkBrand", val: object.brand_id },
            { name: "category", method: "checkCategory", val: { id: object.category_id, type: object.category_type } },
            { name: "description", method: "checkDescription", val: object.description },
            { name: "nullStockTrigger", method: "checkNullStockTrigger", val: object.nullStockTrigger },
            { name: "printDescription", method: "checkPrintDescription", val: object.printDescription },
            { name: "soldOut", method: "checkSoldOut", val: object.soldOut },
            { name: "stockManagement", method: "checkStockManagement", val: object.stockManagement },
            { name: "stockThresholdTrigger", method: "checkStockThresholdTrigger", val: object.stockThresholdTrigger },
            { name: "unit", method: "checkUnit", val: object.unit },
            { name: "variablePrice", method: "checkVariablePrice", val: object.variablePrice }
        ]
        let errors = {}
        let method

        for (let i in params) {
            method = PricelistController[params[i].method]

            try { method.apply(null, params[i].val) }
            catch (e) { errors.params[i].name = e.message }
        }

        if (Object.keys(errors).length > 0) throw new Error(JSON.stringify(errors))
        else return true
    }

    // Controllers

    index(idCatalog, input = "", page = 1, nb = 25, tree = false, sortingName = "", sortingValue = "") {
        let catalog = JSON.parse(sessionStorage.getItem("catalog"));
        let url;
        let params = "";
        if (page > 0) params += (params.length === 0 ? "?" : "&") + "page=" + page + "&nb=" + nb;
        if (input.length > 0) params += (params.length === 0 ? "?" : "&") + "input=" + input;
        if (tree) params += (params.length === 0 ? "?" : "&") + "tree=";
        if (sortingName.length > 0) params += (params.length === 0 ? "?" : "&") + "sorton=" + sortingName + "&sortvalue=" + sortingValue;

        if (catalog !== null && Object.keys(catalog).length > 0)
            url = '/' + this._baseURL.replace("{idCatalog}", catalog.id) + params;
        else if (idCatalog !== undefined && idCatalog !== null)
            url = '/' + this._baseURL.replace("{idCatalog}", idCatalog) + params;
        else
            url = '/' + this._baseURLWithoutCatalog + params;

        this.#_rest.sendQuery(
            'GET',
            url,
            {},
            this.handleIndex);
    }
    handleIndex = (response, error, status = 200) => {
        let listObjects = []
        let pagination = null

        if(status === 200) {
            let data

            if (response.data !== undefined && response.data.data !== undefined) {
                if(response.data.total !== undefined) {
                    pagination = {
                        total: response.data.total,
                        from: response.data.from != null ? response.data.from : 0,
                        to: response.data.to != null ? response.data.to : 0,
                        per_page: response.data.per_page,
                        current_page: response.data.current_page,
                        first_page_url: new URL(response.data.first_page_url).search,
                        last_page_url: new URL(response.data.last_page_url).search,
                        next_page_url: response.data.next_page_url !== null ? new URL(response.data.next_page_url).search : null,
                        prev_page_url: response.data.prev_page_url !== null ? new URL(response.data.prev_page_url).search : null
                    }
                }

                data = response.data.data
            }
            else if (response.data)
                data = response.data

            for(let item in data)
                listObjects.push(new Pricelist(data[item]))
        }

        this._callback(listObjects, error, pagination, status)
    }

    show(idCatalog, id) {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL.replace("{idCatalog}", idCatalog) + '/' + id,
            {},
            this.handleShow)
    }
    handleShow = (response, error, status = 200) => {
        const object = !error ? new Pricelist(response.data) : null
        this._callback(object, error, status)
    }

    post(idCatalog , datas) {
        this.#_rest.sendQuery(
            'POST',
            '/' + this._baseURL.replace("{idCatalog}", idCatalog),
            datas,
            this.handlePost)
    }
    handlePost = (response, error, status) => {
        this._callback(response, error, status)
    }

    put(idCatalog, object, datas) {
        this.#_rest.sendQuery(
            'PUT',
            '/' + this._baseURL.replace("{idCatalog}", idCatalog) + '/' + object.id,
            datas,
            this.handlePut)
    }
    handlePut = (response, error, status) => {
        this._callback(response, error, status)
    }

    delete(object) {
        this.#_rest.sendQuery(
            'DELETE',
            '/' + this._baseURL.replace("{idCatalog}", object.catalog_id) + '/' + object.id,
            {},
            this.handleDelete)
    }
    handleDelete = (response, error, status) => {
        this._callback(response, error, status)
    }

    histo(idCatalog) {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL.replace("{idCatalog}", idCatalog) + '/histos',
            {},
            this.handleHisto)
    }
    handleHisto = (response, error, status = 200) => {
        let listObjects = []

        if(status === 200) {
            let data = response.data

            for(var item in data)
                listObjects.push(data[item])
        }

        this._callback(listObjects, error, status)
    }

    histoTarget(idCatalog, id) {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL.replace("{idCatalog}", idCatalog) + '/' + id + '/histos',
            {},
            this.handleHistoTarget)
    }
    handleHistoTarget = (response, error, status = 200) => {
        let listObjects = []

        if(status === 200) {
            let data = response.data

            for(var item in data)
                listObjects.push(data[item])
        }

        this._callback(listObjects, error, status)
    }
}

export default PricelistController
