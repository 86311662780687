import Entity from "../../../class/abstract/Entity";
import Catalog from "../Catalogs/Catalog";

class Pricelist extends Entity {
    name = null;
    reference = null;
    description = null;
    color = null;
    catalog_id = null;
    catalog = null;
    catalog_name = null;
    main = null;
    last_histo_author = null;

    constructor(datas = {}) {
        super(datas);
        this.setAttributes(datas);
    }

    setAttributes(datas) {
        if (datas === null) return

        let keys = Object.keys(this);
        let key = "";

        for(let i in keys) {
            key = keys[i];

            if (datas[key] !== undefined)
                Reflect.set(this, key, datas[key]);
        }

        if (datas.catalog !== undefined && datas.catalog !== null) {
            this.catalog = new Catalog(datas.catalog)
            this.catalog_name = this.catalog.name
        }
        else if (datas.catalog_id !== null) {
            const catalogs = JSON.parse(sessionStorage.getItem("catalogs"))

            if (catalogs !== null)
                this.catalog = catalogs.find(_ => _.id === datas.catalog_id)
        }

        if (this.catalog !== undefined && this.catalog !== null)
            this.catalog_name = this.catalog.name
    }
}

export default Pricelist;
