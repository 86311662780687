const defaultColors = [
    "#02689E",
    "#FF2D00",
    "#FF7C00",
    "#FFD800",
    "#029E23",
    "#029E97",
    "#1E029E",
    "#7D029E",
    "#9E0202",
    "#ffb56c",
    "#ffee82",
    "#92fca9",
    "#96fff7",
    "#76d0ff",
    "#957dff",
    "#e27eff",
    "#ff80b7",
    "#ff8989"
];
const defaultColorsSmall = [
    "#FF2D00",
    "#FF7C00",
    "#FFD800",
    "#029E23",
    "#029E97",
    "#02689E",
    "#1E029E"
];

class Color {
    static get defaultColors() {
        return defaultColors;
    }
    static get defaultColorsSmall() {
        return defaultColorsSmall;
    }
    static HexToRGB(hex) {
        let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
        hex = hex.replace(shorthandRegex, function(m, r, g, b) {
            return r + r + g + g + b + b
        })

        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null
    }
    static RGBToHex(r, g, b) {
        return "#" + (1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1)
    }
    static RGBtoHSV(color) {
        let rabs, gabs, babs, rr, gg, bb, h, s, v, diff, diffc, percentRoundFn
        rabs = color.r / 255
        gabs = color.g / 255
        babs = color.b / 255
        v = Math.max(rabs, gabs, babs)
        diff = v - Math.min(rabs, gabs, babs)
        diffc = c => (v - c) / 6 / diff + 1 / 2
        percentRoundFn = num => Math.round(num * 100) / 100

        if (diff === 0) {
            h = s = 0
        }
        else {
            s = diff / v
            rr = diffc(rabs)
            gg = diffc(gabs)
            bb = diffc(babs)

            if (rabs === v) {
                h = bb - gg
            }
            else if (gabs === v) {
                h = (1 / 3) + rr - bb
            }
            else if (babs === v) {
                h = (2 / 3) + gg - rr
            }

            if (h < 0) {
                h += 1
            }
            else if (h > 1) {
                h -= 1
            }
        }

        return {
            h: Math.round(h * 360),
            s: percentRoundFn(s * 100),
            v: percentRoundFn(v * 100)
        }
    }
    static HSVtoRGB(color) {
        const colorsys = require('colorsys')
        return colorsys.hsv_to_rgb(color.h, color.s, color.v)
    }
    static shadeColor(col, amt) {
        col = col.replace(/^#/, '')
        if (col.length === 3) col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2]

        let [r, g, b] = col.match(/.{2}/g);
        ([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt])

        r = Math.max(Math.min(255, r), 0).toString(16)
        g = Math.max(Math.min(255, g), 0).toString(16)
        b = Math.max(Math.min(255, b), 0).toString(16)

        const rr = (r.length < 2 ? '0' : '') + r
        const gg = (g.length < 2 ? '0' : '') + g
        const bb = (b.length < 2 ? '0' : '') + b

        return `#${rr}${gg}${bb}`
    }
}

export default Color
