import React, { useEffect, useState } from "react";
import { Chart } from "chart.js";
import ArrowTopIcon from "../../../icons/ArrowTopIcon";
import NumberFormat from "react-number-format";
import Color from "../../../../class/tool/Color";
import "../../../../css/page/content/analyze/Stat.css";

const Stat = props => {
    const { classname, idChart, title, hasChart, hasTable, typeChart, onTablet, nameAmountChart, reinit, model, datas } = props;
    const [ chart, setChart ] = useState(null);
    const [ buildDatas, setBuildDatas ] = useState(null);

    const extractDatas = () => {
        let buildDatasTmp = {
            datas: [],
            total: {}
        };
        let line = {};

        if (
            datas.date !== undefined && datas.date.periods !== undefined && datas.date.periods.datas !== undefined
            && datas.delta !== undefined && datas.delta.periods !== undefined && datas.delta.periods.datas !== undefined
            && datas.versus !== undefined && datas.versus.periods !== undefined && datas.versus.periods.datas !== undefined
        ) {
            for (let key in datas.date.periods.datas) {
                line = {
                    id: parseInt(key)
                };

                for (let i in model) {
                    switch (model[i].type) {
                        case "text":
                            line[model[i].object] = datas.date.periods.datas[key][model[i].object];
                            break;
                        case "int":
                        case "decimal":
                            line[model[i].object] = {
                                value: datas.date.periods.datas[key][model[i].object],
                                versus: datas.versus.periods.datas[key][model[i].object],
                                deltaValue: datas.delta.periods.datas[key][model[i].object + "Value"],
                                deltaPercent: datas.delta.periods.datas[key][model[i].object + "Purcent"]
                            };

                            if (buildDatasTmp.total[model[i].object] === undefined) {
                                buildDatasTmp.total[model[i].object] = {
                                    value: 0,
                                    versus: 0,
                                    deltaValue: 0,
                                    deltaPercent: 0
                                };
                            }

                            buildDatasTmp.total[model[i].object].value += datas.date.periods.datas[key][model[i].object];
                            buildDatasTmp.total[model[i].object].versus += datas.versus.periods.datas[key][model[i].object];
                            buildDatasTmp.total[model[i].object].deltaValue +=  datas.delta.periods.datas[key][model[i].object + "Value"];

                            if (buildDatasTmp.total[model[i].object].value === 0) {
                                buildDatasTmp.total[model[i].object].deltaPercent = -100;
                            }
                            else if (buildDatasTmp.total[model[i].object].versus === 0) {
                                buildDatasTmp.total[model[i].object].deltaPercent = 100;
                            }
                            else {
                                buildDatasTmp.total[model[i].object].deltaPercent = (buildDatasTmp.total[model[i].object].value * 100) / buildDatasTmp.total[model[i].object].versus;
                            }

                            break;
                        default: break;
                    }
                }

                buildDatasTmp.datas.push(line);
            }
        }

        setBuildDatas(buildDatasTmp);
    }
    const initChart = () => {
        if (buildDatas === null)
            return;

        if (chart !== null) {
            updateChart();
            return;
        }

        let options = {};

        switch (typeChart) {
            case "bar":
                options = {
                    responsive: true,
                    borderRadius: 10,
                    indexAxis: onTablet ? 'x' : 'y',
                    animation: {
                        duration: 250 * 1.5,
                        easing: 'linear'
                    },
                    responsiveAnimationDuration: 0,
                    scales: {
                        x: {
                            display: true,
                            border: {
                                display: true
                            },
                            grid: {
                                display: false,
                                drawOnChartArea: false,
                                drawTicks: false
                            }
                        },
                        y: {
                            beginAtZero: true,
                            display: true,
                            grid: {
                                display: true,
                                drawOnChartArea: true,
                                drawTicks: true
                            }
                        }
                    }
                }

                break;
            case "doughnut":
            case "pie":
                options = {
                    responsive: true,
                    borderRadius: 0,
                    animation: {
                        duration: 250 * 1.5,
                        easing: 'linear'
                    },
                    responsiveAnimationDuration: 0,
                    scales: {
                        x: {
                            display: false,
                            border: {
                                display: true
                            },
                            grid: {
                                display: false,
                                drawOnChartArea: false,
                                drawTicks: false
                            }
                        },
                        y: {
                            beginAtZero: true,
                            display: false,
                            grid: {
                                display: false,
                                drawOnChartArea: false,
                                drawTicks: false
                            }
                        }
                    }
                }

                break;
            default:
                break;
        }

        let chartObj = new Chart(idChart, {
            type: typeChart,
            data: {
                datasets: []
            },
            options: options
        });

        setChart(chartObj);
    }
    const updateChart = () => {
        if(chart === null) return;

        let labels = [];
        let datasets = [{
            data: [],
            backgroundColor: [],
            hoverOffset: 6
        }];
        let color;

        for (let i in buildDatas.datas) {
            color = getColor(parseInt(i) + 1);

            labels.push(buildDatas.datas[i][model.find(_ => _.chartLabel === true).object]);

            datasets[0].data.push(buildDatas.datas[i][model.find(_ => _.chartValue === true).object].value);
            datasets[0].backgroundColor.push(color);
        }

        chart.data.labels = labels;
        chart.data.datasets = datasets;
        chart.update();

        animChart(true);
    }
    const getColor = index => {
        if (index <= Color.defaultColors.length) {
            return Color.defaultColors[(index - 1)];
        }
        else {
            let multiplicator = Math.floor(index / Color.defaultColors.length);
            let base = Color.defaultColors.length * multiplicator;
            return Color.defaultColors[(index - base) - 1];
        }
    }
    const animChart = anim => {
        if (chart === null) return;

        if (anim) {
            chart.options.animation = {
                duration: 500
            }
        }
        else {
            chart.options.animation = {
                duration: 0
            }
        }
    }
    const buildValue = (object, type, total = false) => {
        switch (type) {
            case "int":
                return <div className={"value"}>
                    {
                        !total
                        && <NumberFormat className={"deltaPercent " + (parseInt(object.deltaPercent) > 0 ? "more" : (parseInt(object.deltaPercent) < 0 ? "less" : ""))} prefix={(parseInt(object.deltaPercent) >= 0 ? "+ " : "- ")} suffix={" %"} value={Math.abs(parseInt(object.deltaPercent))} decimalScale={0} thousandSeparator={' '} decimalSeparator={','} fixedDecimalScale={true} displayType={'text'} />
                    }
                    {
                        !total
                        && <ArrowTopIcon classname={"arrow" + (parseInt(object.deltaValue) < 0 ? " down" : (parseInt(object.deltaValue) > 0 ? " up" : ""))} />
                    }
                    <NumberFormat className={"value"} value={parseInt(object.value)} decimalScale={0} thousandSeparator={' '} decimalSeparator={','} fixedDecimalScale={true} displayType={'text'} />
                </div>;
            case "decimal":
                return <div className={"value"}>
                    {
                        !total
                        && <NumberFormat className={"deltaPercent " + (parseFloat(object.deltaPercent) > 0 ? "more" : (parseFloat(object.deltaPercent) < 0 ? "less" : ""))} prefix={(parseFloat(object.deltaPercent) >= 0 ? "+ " : "- ")} suffix={" %"} value={Math.abs(parseFloat(object.deltaPercent))} decimalScale={0} thousandSeparator={' '} decimalSeparator={','} fixedDecimalScale={true} displayType={'text'} />
                    }
                    {
                        !total
                        && <ArrowTopIcon classname={"arrow" + (parseFloat(object.deltaValue) < 0 ? " down" : (parseFloat(object.deltaValue) > 0 ? " up" : ""))} />
                    }
                    <NumberFormat className={"value"} value={parseFloat(object.value)} decimalScale={2} thousandSeparator={' '} decimalSeparator={','} fixedDecimalScale={true} displayType={'text'} />
                </div>;
            default:
                return <p>{object}</p>;
        }
    }
    const buildTableContents = () => {
        if (buildDatas === null) return;

        if (buildDatas.datas.length === 0) {
            return <tr>
                <td className="empty">Aucun contenu</td>
            </tr>;
        }

        return buildDatas.datas.map((data, index) => (
            <tr key={index}>
                {
                    model.map((item, index) => (
                        <td key={ index } className={ item.classname }>
                            {
                                buildValue(data[item.object], item.type)
                            }
                        </td>
                    ))
                }
            </tr>
        ));
    }
    const buildTotal = (item, index) => {
        if (buildDatas === null)
            return;

        if (item.totalTitle !== undefined) {
            return item.totalTitle;
        }
        else if (item.total !== undefined && item.total) {
            return buildValue(buildDatas.total[item.object], item.type, true);
        }
    }
    const buildTableTotal = () => {
        if (buildDatas === null || Object.keys(buildDatas.total).length === 0)
            return

        return <tr className="total">
            {
                model.map((item, index) => (
                    <td key={ index } className={ item.classname }>
                        {
                            buildTotal(item, index)
                        }
                    </td>
                ))
            }
        </tr>
    }
    const init = () => {
        if (hasChart)
            initChart();
    }

    useEffect(() => {
        init()
    }, []);
    useEffect(() => {
        extractDatas()
    }, [datas]);
    useEffect(() => {
        initChart();
    }, [buildDatas]);
    useEffect(() => {
        animChart(reinit)
    }, [reinit]);

    return (
        <div className={ "wrapStat" + (classname !== undefined ? " " + classname : "") }>
            <div className={"chartPart"}>
                <p className="title">{title}</p>
                <div className={"containerChart"}>
                    {
                        hasChart
                        && <canvas id={idChart} className={"chart " + typeChart} />
                    }
                </div>
            </div>
            {
                hasTable
                && <div className={"tablePart"}>
                    <table className="table">
                        <tbody>
                            <tr className={"header"}>
                                {
                                    model.map((item, index) => (
                                        <th key={index} className={item.classname}>{item.name}</th>
                                    ))
                                }
                            </tr>
                            {buildTableContents()}
                            {buildTableTotal()}
                        </tbody>
                    </table>
                </div>
            }
        </div>
    )
}

export default Stat;
