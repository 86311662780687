import React, { useState, useEffect } from "react"
import $ from "jquery"
import LoaderCircle from "../../loader/LoaderCircle"
import VatRateController from "../../../stories/_catalog/VatRates/VatRateController"
import Category from "../../../stories/_catalog/Categories/Category"
import FormInline from "../inline/FormInline"
import '../../../css/form/Form.css'

const FormManyCategory = props => {
    const { list, catalogId, handleClose } = props
    const [ loadingVatRates, setLoadingVatRates ] = useState(true)
    const [ vatRates, setVatRates ] = useState([
        {
            value: "Choisir une TVA",
            id: null
        }, {
            value: "----------",
            id: null
        }
    ])
    const [ globalError, setGlobalError ] = useState(null)
    const [ lines, setLines ] = useState([])
    const [ rows, setRows ] = useState([
        {
            attribute: "name",
            inputType: "text",
            returnType: "string",
            classnameColumn: "name",
            classnameInput: "inline",
            classnameNoInput: "",
            placeholder: "Nom",
            emptyText: "Aucun"
        },
        {
            attribute: "vat_rate_id",
            inputType: "select",
            returnType: "int",
            list: [],
            dictionary: null,
            classnameColumn: "vatRate",
            classnameInput: "inline",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucune"
        },
        {
            attribute: "reference",
            inputType: "text",
            returnType: "string",
            classnameColumn: "reference",
            classnameInput: "inline",
            classnameNoInput: "",
            placeholder: "Réference",
            emptyText: "Aucune"
        },
        {
            attribute: "color",
            inputType: "color",
            returnType: "string",
            classnameColumn: "color",
            classnameInput: "inline",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucune"
        }
    ])
    const catalog = JSON.parse(sessionStorage.getItem("catalogs")).find(_ => _.id === catalogId)

    const getVatRates = () => {
        const controller = new VatRateController()
        controller._callback = returnGetVatRates
        controller.index()
    }
    const returnGetVatRates = (list, error, status) => {
        switch (status) {
            case 200:
                setGlobalError(null)

                let vatRatesTmp = vatRates.slice()

                for (let item in list) {
                    vatRatesTmp.push({
                        value: list[item].name,
                        id: list[item].id
                    })
                }

                setVatRates(vatRatesTmp)
                break
            default:
                setGlobalError("Impossible de récupérer les TVA")
                break
        }

        setLoadingVatRates(false)
    }
    const initRows = () => {
        let rowsTmp = rows.slice()
        rowsTmp[rowsTmp.findIndex(_ => _.attribute === "vat_rate_id")].list = vatRates
        setRows(rowsTmp)
    }
    const addLine = vatRate => {
        const linesTmp = lines.slice()
        const category = new Category()

        category.catalog_id = catalog.id
        category.vat_rate_id = vatRate

        linesTmp.push(category)

        setLines(linesTmp)
    }
    const setObject = (key, object) => {
        let linesTmp = lines.slice()

        for(let index in linesTmp) {
            if (key === linesTmp[index].key) {
                linesTmp[index].object = object
                break
            }
        }

        setLines(linesTmp)
    }
    const scrollBottom = () => {
        const id = $(".overbox.manyForm");
        id.scrollTop(id[0].scrollHeight);
    }

    useEffect(() => {
        getVatRates()
    }, [])
    useEffect(() => {
        if (!loadingVatRates)
            initRows()
    }, [loadingVatRates])
    useEffect(() => {
        if (lines.length === 0)
            addLine(null)
    }, [rows])
    useEffect(() => {
        scrollBottom();
    }, [lines])

    return (
        <div className="clearing">
            {
                loadingVatRates
                    ? <LoaderCircle display="loader logWait" hide="" strokeWidth="5" />
                    : <div className="form noPadding">
                        <p className="titleForm center">Ajouter des catégories dans "<b>{ catalog.name }</b>"</p>
                        {
                            globalError !== null
                            && <p className="globalError">{ globalError }</p>
                        }
                        <table className="manyForm">
                            <tbody>
                                <tr className="header">
                                    <th>Nom</th>
                                    <th>TVA</th>
                                    <th>Référence</th>
                                    <th>Couleur</th>
                                    <th className="validate" />
                                </tr>
                                {
                                    lines.map((category, index) => (
                                        <FormInline
                                            key={ index }
                                            list={ list }
                                            object={ category }
                                            type="categories"
                                            rows={ rows }
                                            addLine={ addLine }
                                            setObject={ setObject }
                                            setGlobalError={ setGlobalError } />
                                    ))
                                }
                            </tbody>
                        </table>
                        <button className="cancel noMarginRight" onClick={ handleClose }>Fermer</button>
                        <div className="clearing" />
                    </div>
            }
        </div>
    )
}

export default FormManyCategory
