import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import CategoryController from "../../../stories/_catalog/Categories/CategoryController"
import VatRateController from "../../../stories/_catalog/VatRates/VatRateController"
import LoaderCircle from "../../loader/LoaderCircle"
import FormBuilder from "../../../class/tool/FormBuilder"

const SubCategoryFormSheet = props => {
    const storeSettings = JSON.parse(localStorage.getItem("storeSettings"))
    const { object, values, errors, updated, setGlobalError, handleChange, handleSave } = props
    const [ loadingCategories, setLoadingCategories ] = useState(true)
    const [ loadingVatRates, setLoadingVatRates ] = useState(true)
    const [ categories, setCategories ] = useState([
        {
            value: "Choisir une catégorie",
            id: null
        }, {
            value: "----------",
            id: null
        }
    ])
    const [ vatRates, setVatRates ] = useState([
        {
            value: "Choisir une TVA",
            id: null
        }, {
            value: "----------",
            id: null
        }
    ])
    const [ edit, setEdit ] = useState("")
    const [ rows, setRows ] = useState([
        {
            label: "Nom",
            attribute: "name",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Nom",
            emptyText: "Aucun",
            focus: true,
            edit: (object.sharedUuid === null || (object.sharedUuid !== null && storeSettings !== null && storeSettings.allowedProductCreation === 1))
        },
        {
            label: "Catégorie",
            attribute: "category_id",
            inputType: "select",
            returnType: "int",
            list: categories,
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucune",
            loading: loadingCategories,
            edit: (object.sharedUuid === null || (object.sharedUuid !== null && storeSettings !== null && storeSettings.allowedProductCreation === 1))
        },
        {
            label: "TVA",
            attribute: "vat_rate_id",
            inputType: "select",
            returnType: "int",
            list: vatRates,
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucune",
            loading: loadingVatRates,
            edit: (object.sharedUuid === null || (object.sharedUuid !== null && storeSettings !== null && storeSettings.allowedProductCreation === 1))
        },
        {
            label: "Référence",
            attribute: "reference",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Référence",
            emptyText: "Aucune",
            edit: (object.sharedUuid === null || (object.sharedUuid !== null && storeSettings !== null && storeSettings.allowedProductCreation === 1))
        }
    ])
    const urlParams = useParams()

    const getCategories = () => {
        const controller = new CategoryController()
        controller._callback = returnGetCategories
        controller.index(urlParams.catalogId, "", 0, 0, true)
    }
    const returnGetCategories = (list, error, pagination, status) => {
        switch (status) {
            case 200:
                setGlobalError(null)

                let categoriesTmp = [
                    {
                        value: "Choisir une catégorie",
                        id: null
                    }, {
                        value: "----------",
                        id: null
                    }
                ]

                for (let i in list) {
                    categoriesTmp.push({
                        value: list[i].name,
                        id: list[i].id,
                        object: list[i]
                    })
                }

                setCategories(categoriesTmp)
                break
            default:
                setGlobalError("Impossible de récupérer les catégories")
                break
        }

        setLoadingCategories(false)
    }
    const getVatRates = () => {
        const controller = new VatRateController()
        controller._callback = returnGetVatRates
        controller.index()
    }
    const returnGetVatRates = (list, error, status) => {
        switch (status) {
            case 200:
                let vatRatesTmp = [
                    {
                        value: "Choisir une TVA",
                        id: null
                    }, {
                        value: "----------",
                        id: null
                    }
                ]

                for (let item in list)
                    vatRatesTmp.push({
                        value: list[item].name,
                        id: list[item].id
                    })

                setVatRates(vatRatesTmp)
                break
            default:
                setGlobalError("Impossible de récupérer les TVA")
                break
        }

        setLoadingVatRates(false)
    }
    const refreshRows = () => {
        let rowsTmp = rows.slice()

        rowsTmp[rowsTmp.findIndex(_ => _.attribute === "vat_rate_id")].list = vatRates
        rowsTmp[rowsTmp.findIndex(_ => _.attribute === "category_id")].list = categories
        rowsTmp[rowsTmp.findIndex(_ => _.attribute === "vat_rate_id")].loading = loadingVatRates
        rowsTmp[rowsTmp.findIndex(_ => _.attribute === "category_id")].loading = loadingCategories

        setRows(rowsTmp)
    }
    const buildRow = row => {
        if (edit === row.attribute || ["color"].includes(row.inputType))
            return FormBuilder.buildInputByType(row, values, errors, change, reinitEdit)
        else
            return FormBuilder.buildNoInputByType(row, values, handleEdit)
    }
    const handleEdit = attribute => {
        setEdit(attribute)
    }
    const change = (attribute, returnType, val, strict = false) => {
        handleChange(rows, attribute, returnType, val, strict)
    }
    const reinitEdit = () => {
        setEdit("")
    }
    const handleSubmit = event => {
        if (event !== undefined)
            event.preventDefault()

        handleSave()
    }

    useEffect(() => {
        console.log("VALUES", values)
        getCategories()
        getVatRates()
    }, [])
    useEffect(() => {
        refreshRows()
    }, [vatRates, categories, loadingVatRates, loadingCategories])

    return (
        <form
            className={ "form fullHeight" + (updated ? " withSavingBar" : "") }
            onSubmit={ handleSubmit } >
            {
                loadingVatRates
                ? <div className="wrapCenterLoader">
                    <LoaderCircle
                        display="loader restGETInForm"
                        strokeWidth="5" />
                </div>
                : <div className="clearing">
                        {
                            rows.map((row, index) => (
                                <div key={ index } className="clearing">
                                    <label className={ row.classnameLabel }>{ row.label }</label>
                                    {
                                        buildRow(row)
                                    }
                                </div>
                            ))
                        }
                </div>
            }
        </form>
    );
}

export default SubCategoryFormSheet;
