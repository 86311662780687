import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { Redirect } from "react-router"
import LoaderCircle from "../../loader/LoaderCircle"
import FormBuilder from "../../../class/tool/FormBuilder"
import CatalogController from "../../../stories/_catalog/Catalogs/CatalogController"
import PricelistController from "../../../stories/_catalog/Pricelists/PricelistController"
import '../../../css/form/Form.css'

const FormStart = props => {
    const item = "pricelists"
    const controller = new PricelistController()
    const defaultCatalog = JSON.parse(sessionStorage.getItem("catalog"))

    const { object, handleIndex } = props
    const [ reload, setReload ] = useState(false)
    const [ loadingCatalogs, setLoadingCatalogs ] = useState(true)
    const [ catalogs, setCatalogs ] = useState([
        {
            value: "Choisir un catalogue",
            id: null
        }, {
            value: "----------",
            id: null
        }
    ])
    const [ values, setValues ] = useState({})
    const [ errors, setErrors ] = useState([])
    const [ saving, setSaving ] = useState(false)
    const [ globalError, setGlobalError ] = useState(null)
    const [ rows, setRows ] = useState([
        {
            key: 1,
            label: "Nom",
            attribute: "name",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Nom",
            emptyText: "Aucun"
        },
        {
            key: 2,
            label: "Réference",
            attribute: "reference",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Réference",
            emptyText: "Aucune"
        },
        {
            key: 3,
            label: "Couleur",
            attribute: "color",
            inputType: "color",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucune"
        }
    ])

    const getCatalogs = () => {
        const controller = new CatalogController()
        controller._callback = handleGetCatalogs
        controller.index("", 0, 0, true, "name", "asc")
    }
    const handleGetCatalogs = (list, error, pagination, status) => {
        switch (status) {
            case 200:
                sessionStorage.setItem("catalogs", JSON.stringify(list))

                if (list.length === 1)
                    sessionStorage.setItem("catalog", JSON.stringify(list[0]))
                else
                    sessionStorage.setItem("catalog", JSON.stringify({}))

                let listTmp = catalogs.slice()

                for (let i in list) {
                    listTmp.push({
                        value: list[i].name,
                        id: list[i].id,
                        readonly: list[i].planned_deployments_to.length > 0
                    })
                }

                setCatalogs(listTmp)
                break
            default:
                setGlobalError("Impossible de récupérer les catalogues")
                break
        }

        setLoadingCatalogs(false)
    }
    const initValues = () => {
        controller.setFormValues(object, null, null, null, setValues)
    }
    const refreshRows = () => {
        let rowsTmp = rows.slice()

        if (catalogs.length > 3) {
            if (!rowsTmp.find(_ => _.attribute === "catalog_id")) {
                rowsTmp.splice(1, 0, {
                    label: "Catalogue",
                    attribute: "catalog_id",
                    inputType: "select",
                    returnType: "int",
                    list: catalogs,
                    dictionary: null,
                    classnameLabel: "label",
                    classnameInput: "",
                    classnameNoInput: "",
                    placeholder: "",
                    emptyText: "Aucun"
                })
            }
            else
                rowsTmp[rowsTmp.findIndex(_ => _.attribute === "catalog_id")].list = catalogs
        }

        setRows(rowsTmp)
    }
    const handleChange = (attribute, returnType, val, strict = false) => {
        let value = FormBuilder.buildVal(returnType, val);
        let filtered = rows.filter(row => row.attribute === attribute && row.inputType === "select" && row.returnType === "int");
        let index = value;

        if (!strict && filtered.length > 0 && filtered[0].list.length > 0) {
            value = parseInt(filtered[0].list[index].id);

            if (filtered[0].list[index].type !== undefined && values[attribute.replace("_id", "_type")] !== undefined) {
                setValues(prev => ({
                    ...prev,
                    [attribute]: value,
                    [attribute.replace("_id", "_type")]: filtered[0].list[index].type
                }));
            }
            else {
                setValues(prev => ({
                    ...prev,
                    [attribute]: value
                }));
            }
        }
        else {
            filtered = rows.filter(row => row.attribute === attribute && row.inputType === "select" && row.returnType === "string")

            if (filtered.length > 0) {
                if (filtered[0].list.filter(_ => _.value === value && _.id !== undefined && _.id === null).length > 0)
                    value = null
            }

            setValues(prev => ({
                ...prev,
                [attribute]: value
            }))
        }
    }
    const callToSave = () => {
        setGlobalError(null)
        reinitAllEdits()
        save()
    }
    const reinitAllEdits = () => {
        setErrors([]);
    }
    const returnUpdates = () => {
        return controller.returnUpdatesFromCompare(object, values);
    }
    const check422Errors = errorDatas => {
        setGlobalError("Certaines données sont invalides");

        if(errorDatas !== undefined) {
            var keys = Object.keys(errorDatas);
            var fields = ["reference"];

            for(var item in fields)
                if(keys.includes(fields[item]))
                    defineErrors(fields[item], false);
        }
    }
    const defineErrors = (type, empty) => {
        let errorsTmp = errors.slice();

        switch (type) {
            case "name":
                if(empty) errorsTmp["name"] = "Vous devez saisir un nom";
                else errorsTmp["name"] = "Ce nom n'est pas valide";
                break;
            default: break;
        }

        setErrors(errorsTmp);
    }
    const save = () => {
        const datas = returnUpdates()

        if(Object.keys(datas).length === 0) return

        setSaving(true)

        controller._callback = handleReturnSave;
        controller.post(Object.keys(defaultCatalog).length > 0 ? defaultCatalog.id : values.catalog_id, datas)
    }
    const handleReturnSave = (response, error, status) => {
        setSaving(false)

        switch (status) {
            case 201:
                if (values.catalog_id !== undefined && values.catalog_id !== null) // Enregistre pour les futurs création la pré-sélection du catalogue
                    sessionStorage.setItem("defaultCatalogIdInForm", values.catalog_id)

                setReload(true)
                handleIndex()
                break
            case 422:
                check422Errors(error)
                break
            default:
                setGlobalError("Une erreur s'est produite lors de l'enregistrement du tarif")
                break
        }
    }
    const handleSubmit = event => {
        if (event !== undefined)
            event.preventDefault();
        callToSave();
    }

    useEffect(() => {
        getCatalogs()
        initValues()
    }, [])
    useEffect(() => {
        refreshRows()
    }, [loadingCatalogs, catalogs])

    return (
        <div className="clearing">
            {
                reload && <Redirect to={ "/" + item } />
            }
            <form className="form" onSubmit={handleSubmit}>
                {
                    globalError !== null && <p className="globalError">{ globalError }</p>
                }
                {
                    rows.map((row, index) => (
                        <div key={ index } className="clearing">
                            <label className={ row.classnameLabel }>{ row.label }</label>
                            {
                                FormBuilder.buildInputByType(row, values, errors, handleChange, null, null, handleSubmit, null, null, null, index === 0)
                            }
                        </div>
                    ))
                }
                <button className={"submit " + (saving ? "hide" : "")}>
                    {
                        saving
                            ? "Enregistrement..."
                            : "Enregistrer"
                    }
                    <LoaderCircle display="loader submitForm " hide={!saving ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF" />
                </button>
                <Link to={ "/" + item }>
                    <button className="cancel align">Fermer</button>
                </Link>
                <div className="clearing" />
            </form>
        </div>
    )
}

export default FormStart
