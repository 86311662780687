import React, { useState, useEffect } from "react"
import LoaderCircle from "../../loader/LoaderCircle"
import CategoryController from "../../../stories/_catalog/Categories/CategoryController"
import SubCategoryController from "../../../stories/_catalog/SubCategories/SubCategoryController"
import ProductController from "../../../stories/_catalog/Products/ProductController"
import SellerController from "../../../stories/_setting/Sellers/SellerController"
import FormBuilder from "../../../class/tool/FormBuilder"
import '../../../css/form/Form.css'
import CatalogController from "../../../stories/_catalog/Catalogs/CatalogController";
import Color from "../../../class/tool/Color";

const FormInline = props => {
    const env = JSON.parse(localStorage.getItem("env"))
    const { list, type, object, rows, addLine, setGlobalError } = props
    const [ id, setId ] = useState(object.id)
    const [ values, setValues ] = useState({})
    const [ errors, setErrors ] = useState([])
    const [ saving, setSaving ] = useState(false)
    const [ updating, setUpdating ] = useState(false)

    const initValues = () => {
        const controller = getController()

        switch (type) {
            case "categories":
                controller.setFormValues(object, setValues)
                handleChange("color", "string", defineAutoColor())
                break
            case "subcategories":
                controller.setFormValues(object, setValues)
                break;
            case "products":
                controller.setFormValues(object, null, null, null, setValues, true)
                break;
            case "sellers":
                controller.setFormValues(object, setValues)
                break;
            default: break;
        }
    }
    const handleChange = (attribute, returnType, val, strict = false) => {
        setGlobalError(null)

        const obj = FormBuilder.handleChange(rows, setValues, attribute, returnType, val, strict)

        switch (attribute) {
            case "category_id":
                if (obj.filtered.length > 0 && obj.filtered[0].list.length > 0 && obj.filtered[0].list[obj.index].object !== undefined)
                    handleChange("vat_rate_id", "int", obj.filtered[0].list[obj.index].object.vat.id, true)

                break
            default: break
        }
    }
    const reinitAllEdits = () => {
        setErrors([])
    }
    const returnUpdates = (controller, compare = false) => {
        return controller.returnUpdatesFromCompare(object, values, compare)
    }
    const check422Errors = errorDatas => {
        setGlobalError("Certaines données sont invalides")

        if(errorDatas !== undefined) {
            let keys = Object.keys(errorDatas)
            let fields = ["reference"]

            for(let item in fields)
                if(keys.includes(fields[item]))
                    defineErrors(fields[item], false)
        }
    }
    const defineErrors = (type, empty) => {
        let errorsTmp = errors.slice()

        switch (type) {
            case "name":
                if(empty) errorsTmp["name"] = "Vous devez saisir un nom"
                else errorsTmp["name"] = "Ce nom n'est pas valide"
                break
            default: break
        }

        setErrors(errorsTmp)
    }
    const getController = () => {
        switch (type) {
            case "categories": return new CategoryController()
            case "subcategories": return new SubCategoryController()
            case "products": return new ProductController()
            case "sellers": return new SellerController()
            default: break
        }
    }
    const checkDiff = () => {
        const datas = returnUpdates(getController(), true)

        setUpdating(Object.keys(datas).length > 0)
    }
    const handleSubmit = event => {
        if (event !== undefined)
            event.preventDefault()

        callToSave()
    }
    const callToSave = () => {
        setGlobalError(null)
        reinitAllEdits()

        save()
    }
    const save = () => {
        const catalogSession = JSON.parse(sessionStorage.getItem("catalog"))
        const controller = getController()
        const datas = returnUpdates(controller, (object.id > 0))

        if(Object.keys(datas).length === 0) return

        setSaving(true)

        controller._callback = returnSave

        if (["categories", "subcategories", "products"].includes(type)) {
            const catalogs = JSON.parse(sessionStorage.getItem("catalogs"))
            const catalog = catalogs.find(_ => _.id === datas.catalog_id)

            switch (type) {
                case "categories":
                case "subcategories":
                    datas.vats = {}

                    switch (env.type) {
                        case "company":
                            for (let i in catalog.stores)
                                datas.vats[env.stores.find(_ => _.shortName === catalog.stores[i]).id] = datas.vat_rate_id

                            break
                        case "store":
                            datas.vats[env.id] = datas.vat_rate_id
                            break
                        default: break
                    }

                    delete datas.vat_rate_id

                    break
                case "products":
                    datas.vats = {}
                    datas.prices = {}

                    switch (env.type) {
                        case "company":
                            for (let i in catalog.stores) {
                                if (datas.vat_rate_id !== undefined && datas.vat_rate_id !== null)
                                    datas.vats[env.stores.find(_ => _.shortName === catalog.stores[i]).id] = datas.vat_rate_id

                                if (!datas.variablePrice)
                                    datas.prices[env.stores.find(_ => _.shortName === catalog.stores[i]).id] = datas.price
                            }

                            break
                        case "store":
                            datas.vats[env.id] = datas.vat_rate_id

                            if (!datas.variablePrice)
                                datas.prices[env.id] = datas.price

                            break
                        default: break
                    }

                    delete datas.vat_rate_id
                    delete datas.price

                    if (datas.variablePrice)
                        delete datas.prices

                    break
                default: break
            }

            if (object.id === 0)
                controller.post(Object.keys(catalogSession).length > 0 ? catalogSession.id : values.catalog_id, datas)
            else
                controller.put(Object.keys(catalogSession).length > 0 ? catalogSession.id : values.catalog_id, object, datas)
        }
        else {
            if (object.id === 0)
                controller.post(datas)
            else
                controller.put(object, datas)
        }
    }
    const returnSave = (response, error, status) => {
        setSaving(false)

        switch (status) {
            case 201:
                setId(response.data.id)
                completeObject(response.data.id)
                callAddLine()

                break
            case 204:
                completeObject(null)
                break
            case 422:
                check422Errors(error.response.data)
                break
            default:
                setGlobalError("Une erreur s'est produite lors de l'enregistrement")
                break
        }
    }
    const completeObject = id => {
        if (id !== null)
            object.id = id

        let datas = returnUpdates(getController())
        let dataKeys = Object.keys(datas)
        let key

        for(let i in dataKeys) {
            key = dataKeys[i]

            if (object[key] !== undefined)
                object[key] = datas[key]
        }

        checkDiff()
    }
    const callAddLine = () => {
        switch (type) {
            case "categories":
                addLine(values.vat_rate_id)
                break
            case "subcategories":
                addLine(values.category_id, values.vat_rate_id)
                break
            case "products":
                addLine(values.category_id, values.category_type, values.vat_rate_id)
                break
            case "sellers":
                addLine(values.seller_group_id)
                break
            default:
                addLine()
                break
        }
    }
    const defineAutoColor = () => {
        return Color.defaultColors[Math.floor(Math.random() * Color.defaultColors.length)];
    }

    useEffect(() => {
        initValues()
    }, [])
    useEffect(() => {
        checkDiff()
    }, [values])

    return (
        <tr>
            {
                rows.map((row, index) => (
                    <td key={ index } className={ row.classnameColumn }>
                        {
                            FormBuilder.buildInputByType(row, values, errors, handleChange, null, null, handleSubmit, null, null, null, index === 0)
                        }
                    </td>
                ))
            }
            <td className="validate">
                {
                    saving
                        ? <LoaderCircle strokeWidth="10" />
                        : (
                            id === 0
                                ? <p onClick={ callToSave }>Ajouter</p>
                                : (
                                    updating
                                    && <p onClick={ callToSave }>Modifier</p>
                                )
                        )
                }
            </td>
        </tr>
    )
}

export default FormInline
