import React, {useEffect, useState} from "react";
import $ from "jquery";
import DownArrow from "../../../icons/DownArrow";
import "../../../../css/form/ButtonAction.css";

const ButtonAction = props => {
    const { classname, actions, actionClick, random } = props;
    const [ subItemOpened, setSubItemOpened ] = useState(false);
    const [ secondaryActions, setSecondaryActions ] = useState([]);
    const idRandom = random !== undefined ? random : Math.floor(Math.random() * 999999);

    const initSecondaryActions = () => {
        setSecondaryActions(actions.slice(1));
    }
    const initSubItemListPosition = () => {
        const buttonElmt = $("#ButtonAction_" + idRandom);
        const subItemListElmt = $("#listSubItem_" + idRandom);

        if (buttonElmt.length === 0 || subItemListElmt.length === 0)
            return;

        const offset = buttonElmt.offset();
        const yPos = offset.top + buttonElmt.height() + 15;
        const xPos = offset.left + buttonElmt.width() - subItemListElmt.width();

        subItemListElmt.css({ top: yPos, left: xPos });
    }
    const mainAction = () => {
        if (actions.length === 0)
            return;

        if (actions[0].actionCode === undefined)
            return;

        actionClick(actions[0].actionCode);

        if (subItemOpened && actions[0].closeWhenAction !== undefined && actions[0].closeWhenAction)
            open();
    }
    const secondaryAction = action => {
        if (action.actionCode === undefined)
            return;

        actionClick(action.actionCode);
        open();
    }
    const open = () => {
        initSubItemListPosition();
        setSubItemOpened(!subItemOpened);
    }

    useEffect(() => {
        initSubItemListPosition();
    }, []);
    useEffect(() => {
        initSecondaryActions();
    }, [actions]);
    useEffect(() => {
        if (subItemOpened) {
            window.addEventListener('resize', initSubItemListPosition);
            window.addEventListener('scroll', initSubItemListPosition);

            if ($("#calendar_" + idRandom).length > 0) {
                $("#calendar_" + idRandom).css({ visibility: "collapse" })
            }

            $("#listSubItem_" + idRandom).css({ visibility: "visible" });
        }
        else {
            window.removeEventListener('resize', initSubItemListPosition);
            window.removeEventListener('scroll', initSubItemListPosition);

            setTimeout(() => { $("#listSubItem_" + idRandom).css({ visibility: "collapse" }) }, 200);
        }
    }, [subItemOpened]);

    return (
        <>
            <div id={"ButtonAction_" + idRandom} className={"ButtonAction" + (classname !== undefined ? " " + classname : "") + (subItemOpened ? " opened" : "")}>
                <div className={"mainAction"} onClick={mainAction}>
                    {
                        actions.length > 0
                        && actions[0].icon
                    }
                    <p className={"text"}>{ (actions.length > 0 ? actions[0].title : "") }</p>
                </div>
                {
                    actions.length > 1
                    && <div className={"secondaryActions"} onClick={open}>
                        <DownArrow classname={"down"}/>
                    </div>
                }
            </div>
            <div id={"listSubItem_" + idRandom} className={"ButtonActionSubItemList" + (subItemOpened ? " visible" : "")}>
                {
                    secondaryActions.map((action, index) => (
                        <div key={index} className={"secondaryAction" + (action.classname !== undefined ? " " + action.classname : "")} onClick={() => { secondaryAction(action) }}>
                            {
                                action.icon !== undefined
                                && action.icon
                            }
                            <p className={"text"}>{ action.title }</p>
                        </div>
                    ))
                }
            </div>
        </>
    )
}

export default ButtonAction
