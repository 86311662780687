import React, { useState, useEffect } from "react"
import LoaderCircle from "../../loader/LoaderCircle"
import FormBuilder from "../../../class/tool/FormBuilder"
import CatalogController from "../../../stories/_catalog/Catalogs/CatalogController"
import '../../../css/form/Form.css'

const FormStart = props => {
    const { setCatalogId, setGlobalError, handleClose } = props
    const [ loadingCatalogs, setLoadingCatalogs ] = useState(false)
    const [ catalogs, setCatalogs ] = useState([
        {
            value: "Choisir un catalogue",
            id: null
        }, {
            value: "----------",
            id: null
        }
    ])
    const [ values, setValues ] = useState({
        catalog_id: null
    })
    const [ rows, setRows ] = useState([
        {
            label: "Catalogue",
            attribute: "catalog_id",
            inputType: "select",
            returnType: "int",
            list: catalogs,
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucun"
        }
    ])

    const getCatalogs = () => {
        const controller = new CatalogController()
        controller._callback = handleGetCatalogs
        controller.index("", 0, 0, true, "name", "asc")
    }
    const handleGetCatalogs = (list, error, pagination, status) => {
        switch (status) {
            case 200:
                setGlobalError(null)

                sessionStorage.setItem("catalogs", JSON.stringify(list))

                if (list.length === 1)
                    sessionStorage.setItem("catalog", JSON.stringify(list[0]))
                else
                    sessionStorage.setItem("catalog", JSON.stringify({}))

                let listTmp = catalogs.slice()

                for (let i in list) {
                    listTmp.push({
                        value: list[i].name,
                        id: list[i].id,
                        readonly: list[i].planned_deployments_to.length > 0
                    })
                }

                setCatalogs(listTmp)
                break
            default:
                setGlobalError("Impossible de récupérer les catalogues")
                break
        }

        setLoadingCatalogs(false)
    }
    const refreshRows = () => {
        let rowsTmp = rows.slice()
        rowsTmp[rowsTmp.findIndex(_ => _.attribute === "catalog_id")].list = catalogs
        setRows(rowsTmp)
    }
    const handleChange = (attribute, returnType, val, strict = false) => {
        const obj = FormBuilder.handleChange(rows, setValues, attribute, returnType, val, strict)

        switch (attribute) {
            case "catalog_id":
                setCatalogId(obj.value)
                break
            default: break
        }
    }

    useEffect(() => {
        getCatalogs()
    }, [])
    useEffect(() => {
        refreshRows()
    }, [catalogs, loadingCatalogs])

    return (
        <div className="clearing">
            {
                loadingCatalogs
                    ? <LoaderCircle display="loader logWait" hide="" strokeWidth="5"/>
                    : <form className="form noPadding">
                        {
                            rows.map((row, index) => (
                                <div key={ index } className="clearing">
                                    <label className={ row.classnameLabel }>{ row.label }</label>
                                    {
                                        FormBuilder.buildInputByType(row, values, [], handleChange, null, null, null, null, null, null, index === 0)
                                    }
                                </div>
                            ))
                        }
                        <button className="cancel noMarginRight" onClick={ handleClose }>Fermer</button>
                        <div className="clearing" />
                    </form>
            }

        </div>
    )
}

export default FormStart
