import React, { useEffect, useState } from "react"
import CategoryController from "../../../stories/_catalog/Categories/CategoryController"
import LoaderCircle from "../../loader/LoaderCircle"
import FormBuilder from "../../../class/tool/FormBuilder"
import {useParams} from "react-router-dom";

const ProductFormSheet = props => {
    const urlParams = useParams()
    const storeSettings = JSON.parse(localStorage.getItem("storeSettings"))
    const { object, values, errors, updated, setGlobalError, handleChange, handleSave } = props
    const [ loadingCategories, setLoadingCategories ] = useState(true)
    const [ categories, setCategories ] = useState([
        {
            value: "Choisir une catégorie",
            id: null
        }, {
            value: "----------",
            id: null
        }
    ])
    const [ edit, setEdit ] = useState("")
    const [ rows, setRows ] = useState([
        {
            label: "Catégorie",
            attribute: "category_id",
            inputType: "select",
            returnType: "int",
            list: [],
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucune",
            readOnly: true,
            edit: (object.sharedUuid === null || (object.sharedUuid !== null && storeSettings !== null && storeSettings.allowedProductCreation === 1))
        },
        {
            label: "Réference",
            attribute: "reference",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Réference",
            emptyText: "Aucune",
            edit: (object.sharedUuid === null || (object.sharedUuid !== null && storeSettings !== null && storeSettings.allowedProductCreation === 1))
        },
        {
            label: "Nom du produit",
            attribute: "name",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Nom",
            emptyText: "Aucun",
            edit: (object.sharedUuid === null || (object.sharedUuid !== null && storeSettings !== null && storeSettings.allowedProductCreation === 1))
        },
        {
            label: "Libellé de la touche",
            attribute: "label",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Libellé",
            emptyText: "Aucun",
            edit: (object.sharedUuid === null || (object.sharedUuid !== null && storeSettings !== null && storeSettings.allowedProductCreation === 1))
        },
        {
            label: "Description",
            attribute: "description",
            inputType: "textarea",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Description",
            emptyText: "Aucune",
            edit: (object.sharedUuid === null || (object.sharedUuid !== null && storeSettings !== null && storeSettings.allowedProductCreation === 1))
        }
    ])

    const getCategories = () => {
        const controller = new CategoryController();
        controller._callback = returnGetCategories;
        controller.index(urlParams.catalogId, "", 0, 0, true);
    }
    const returnGetCategories = (list, error, pagination, status) => {
        switch (status) {
            case 200:
                setGlobalError(null)

                let categoriesTmp = [
                    {
                        value: "Choisir une catégorie",
                        id: null
                    }, {
                        value: "----------",
                        id: null
                    }
                ]

                for (var i in list) {
                    categoriesTmp.push({
                        value: list[i].name,
                        id: list[i].id,
                        type: "categories",
                        object: list[i]
                    });

                    for (var j in list[i].subCategories) {
                        categoriesTmp.push({
                            value: "--- " + list[i].subCategories[j].name,
                            id: list[i].subCategories[j].id,
                            type: "subcategories",
                            object: list[i].subCategories[j]
                        });
                    }
                }

                setCategories(categoriesTmp)
                break
            default:
                setGlobalError("Impossible de récupérer les catégories")
                break
        }

        setLoadingCategories(false)
    }
    const refreshRows = () => {
        let rowsTmp = rows.slice()

        rowsTmp[rowsTmp.findIndex(_ => _.attribute === "category_id")].list = categories
        rowsTmp[rowsTmp.findIndex(_ => _.attribute === "category_id")].loading = loadingCategories

        setRows(rowsTmp)
    }
    const buildRow = row => {
        if ((row.edit === undefined || row.edit) && (edit === row.attribute || ["color"].includes(row.inputType)))
            return FormBuilder.buildInputByType(row, values, errors, change, reinitEdit)
        else
            return FormBuilder.buildNoInputByType(row, values, handleEdit)
    }
    const handleEdit = attribute => {
        setEdit(attribute);
    }
    const change = (attribute, returnType, val, strict = false) => {
        handleChange(rows, attribute, returnType, val, strict)
    }
    const reinitEdit = () => {
        setEdit("");
    }
    const handleSubmit = event => {
        if (event !== undefined)
            event.preventDefault();
        handleSave();
    }

    useEffect(() => {
        getCategories()
    }, [])
    useEffect(() => {
        refreshRows()
    }, [categories, loadingCategories])

    return (
        <form
            className={ "form fullHeight" + (updated ? " withSavingBar" : "") }
            onSubmit={ handleSubmit }>
            {
                loadingCategories
                    ? <div className="wrapCenterLoader">
                        <LoaderCircle
                            display="loader restGETInForm"
                            strokeWidth="5" />
                    </div>
                    : <div className="clearing">
                        {
                            rows.map((row, index) => (
                                <div key={ index } className="clearing">
                                    <label className={ row.classnameLabel }>{ row.label }</label>
                                    {
                                        buildRow(row)
                                    }
                                </div>
                            ))
                        }
                    </div>
            }
        </form>
    );
}

export default ProductFormSheet
