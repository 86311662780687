import Rest from "../../../class/tool/Rest"
import PosScreen from "./PosScreen"
import FormBuilder from "../../../class/tool/FormBuilder"
import moment from "moment"
moment.locale("fr")

class PosScreenController {
    #_rest = new Rest()
    _callback = null
    _baseURL = "posscreens"
    _baseURLListStoreScreens = "companies/{idCompany}/stores/posscreens"

    setFormValues(object, setValues) {
        setValues(prev => ({
            ...prev,
            name: object.name,
            format: object.format,
            displayPosition: object.displayPosition,
            licenses: object.licenses,
            sellers: object.sellers
        }))
    }
    returnUpdatesFromCompare(object, datas, compare = true) {
        return FormBuilder.returnUpdates(object, datas, compare)
    }
    checkValues(object) {
        const params = [
            { name: "name", method: "checkName", val: object.name }
        ]
        let errors = {}
        let method

        for (let i in params) {
            method = PosScreenController[params[i].method]

            try { method.apply(null, params[i].val) }
            catch (e) { errors.params[i].name = e.message }
        }

        if (Object.keys(errors).length > 0) throw new Error(JSON.stringify(errors))
        else return true
    }

    index() {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL,
            {},
            this.handleIndex)
    }
    handleIndex = (response, error, status) => {
        let listObjects = []

        if(!error) {
            if(response.data !== undefined) {
                const data = response.data

                for(let item in data)
                    listObjects.push(new PosScreen(data[item]))
            }
        }

        this._callback(listObjects, error, status)
    }

    indexForCompany(idCompany) {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURLListStoreScreens.replace("{idCompany}", idCompany),
            {},
            this.handleIndexForCompany)
    }
    handleIndexForCompany = (response, error, status = 200) => {
        let listObjects = []
        const env = JSON.parse(localStorage.getItem("env"))

        if(status === 200) {
            let data = response.data
            let obj

            for(let key in data) {
                obj = {
                    store: {},
                    screens: []
                }

                for (let i in env.stores) {
                    if (env.stores[i].id === parseInt(key)) {
                        obj.store = env.stores[i]
                        break
                    }
                }

                for (let i in data[key])
                    obj.screens.push(new PosScreen(data[key][i]))

                if (obj.screens.length > 0)
                    listObjects.push(obj)
            }
        }

        if (this._callback !== null)
            this._callback(listObjects, error, status)
    }

    show(id) {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL + '/' + id,
            {},
            this.handleShow);
    }
    handleShow = (response, error, status) => {
        const object = !error ? new PosScreen(response.data) : null
        this._callback(object, error, status)
    }

    post(datas, needsKeyboards = true) {
        let datasToSend = {}

        if (needsKeyboards) {
            if (datas.name !== undefined) datasToSend.name = datas.name;

            datasToSend.displayPosition = datas.displayPosition !== undefined ? datas.displayPosition : "left";
            datasToSend.format = datas.format !== undefined ? datas.format : "format16/9";
        }
        else {
            datasToSend.needskeyboards = false;

            if (datas.source_id !== undefined) {
                datasToSend.source_id = datas.source_id;
            }
            else {
                if (datas.name !== undefined) datasToSend.name = datas.name;
                if (datas.displayPosition !== undefined) datasToSend.displayPosition = datas.displayPosition;
                if (datas.format !== undefined) datasToSend.format = datas.format;
            }

            if (datas.store_id !== undefined && datas.sharedUuid !== null) {
                this.#_rest._context = datas.store_id;
                datasToSend.store_id = datas.store_id;
            }
        }

        this.#_rest.sendQuery(
            'POST',
            '/' + this._baseURL,
            datasToSend,
            this.handlePost
        );
    }
    handlePost = (response, error, status, context) => {
        this._callback(response, error, status, context);
    }

    put(object, datas) {
        this.#_rest.sendQuery(
            'PUT',
            '/' + this._baseURL + '/' + object.id,
            datas,
            this.handlePut)
    }
    handlePut = (response, error, status) => {
        this._callback(response, error, status)
    }

    deleteById(idObject) {
        this.#_rest.sendQuery(
            'DELETE',
            '/' + this._baseURL + '/' + idObject,
            {
                "hardDelete": true
            },
            this.handleDelete)
    }
    delete(object) {
        this.#_rest.sendQuery(
            'DELETE',
            '/' + this._baseURL + '/' + object.id,
            {},
            this.handleDelete)
    }
    handleDelete = (response, error, status) => {
        this._callback(response, error, status)
    }

    rise(storeScreenId, companyScreenId) {
        this.#_rest._context = companyScreenId
        this.#_rest.sendQuery(
            'POST',
            '/posscreens/' + storeScreenId + '/rise',
            {
                "target_screen_id": companyScreenId
            },
            this.handleRise)
    }
    handleRise = (response, error, status, context) => {
        if (this._callback !== null)
            this._callback(response, error, status, context)
    }

    deploy(catalogId, proposeAt) {
        this.#_rest.sendQuery(
            'POST',
            '/posscreens/' + catalogId + '/deploy',
            {
                "propose_at": moment(proposeAt).format()
            },
            this.handleDeploy)
    }
    handleDeploy = (response, error, status) => {
        if (this._callback !== null)
            this._callback(response, error, status)
    }

    sync(idPosScreen) {
        this.#_rest.sendQuery(
            'POST',
            '/' + this._baseURL + '/' + idPosScreen + '/synchronize',
            {},
            this.handleSync)
    }
    handleSync = (response, error, status) => {
        this._callback(response, error, status)
    }

    unsyncUpdates(idPosScreen) {
        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL + '/' + idPosScreen + '/unsyncupdates',
            {},
            this.handleUnsyncUpdates)
    }
    handleUnsyncUpdates = (response, error, status) => {
        this._callback(response, error, status)
    }
}

export default PosScreenController
