import React from "react";

export default React.createContext({
    setBubbleText: () => {},
    setErrorText: () => {},
    setValidText: () => {},
    getCatalogDeploymentInProgress: () => {},
    getScreenDeploymentInProgress: () => {},
    showToDeployButton: null,
    showDeployingCatalog: null,
    showDeployingScreen: null
});
