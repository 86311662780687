import React, {useContext, useEffect} from "react"
import $ from "jquery"
import { v4 as uuidv4 } from 'uuid'
import KeyComponent from "./KeyComponent"
import Key from "../../../../stories/_setting/Keys/Key"
import ScreenContext from "../../../../context/ScreenContext"

const Keyboard = props => {
    const {classname, select, keyboard, handleOpenAction} = props
    const {products, keyboards, categoryKeySelected} = useContext(ScreenContext)

    const resize = () => {
        const idKeyboard = "#keyboard-" + keyboard.zone
        let widthKeyboard = $(idKeyboard).width()
        let heightKeyboard = $(idKeyboard).height()

        switch (keyboard.zone) {
            case "T":
                $(idKeyboard + " .key").css({width: (widthKeyboard / keyboard.nbColumns) + "px"})
                break
            case "M":
            case "S":
                $(idKeyboard + " .key").css({width: ((widthKeyboard / keyboard.nbColumns) - 4) + "px"})
                break
            case "R":
                $(idKeyboard + " .key").css({
                    width: (widthKeyboard / keyboard.nbColumns) + "px",
                    height: (heightKeyboard / keyboard.nbLines) + "px"
                })

                break
            case "P":
                $(idKeyboard + " .key").css({
                    width: (((widthKeyboard - 6) / keyboard.nbColumns) - 6) + "px",
                    height: (((heightKeyboard - 6) / keyboard.nbLines) - 6) + "px"
                })

                break
            default: break
        }
    }
    const fillKeyboard = () => {
        const maxRankDisplayed = keyboard.nbLines * keyboard.nbColumns
        let displayedLines = keyboard.nbLines
        let displayedColumns = keyboard.nbColumns
        let maxRank = Math.max(...keyboard.keys.map(_ => { return _.rank }))

        if (keyboard.type === "M") {
            if (maxRank <= maxRankDisplayed)
                maxRank = maxRankDisplayed + displayedColumns
            else
                maxRank = maxRankDisplayed + (2 * displayedColumns) + (maxRank % maxRank / displayedColumns)

            while (maxRank > (keyboard.nbColumns * displayedLines))
                displayedLines++
        }
        else if (keyboard.type !== "P") {
            if (maxRank <= maxRankDisplayed)
                maxRank = maxRankDisplayed + 1
            else
                maxRank++

            while (maxRank > (keyboard.nbLines * displayedColumns))
                displayedColumns++
        }

        if (keyboard.type === "P" && categoryKeySelected !== null && categoryKeySelected.action_code === 501) {
            keyboard.keys = []

            let bgColor = categoryKeySelected.color
            let productList = products.filter(_ => _.category_type === keyboard.keyboardable_type && _.category_id === keyboard.keyboardable_id)

            switch (keyboard.sorting) {
                case "a-z":
                    productList = productList.sort((o1, o2) => {
                        const nameA = o1.name.toLocaleUpperCase()
                        const nameB = o2.name.toLocaleUpperCase()
                        const compare = nameA.localeCompare(nameB, undefined, {numeric: true, sensitivity: "base"})

                        if (compare > 0)
                            return 1
                        else if (compare < 0)
                            return -1
                        else
                            return 0
                    })

                    break
                case "z-a":
                    productList = productList.sort((o1, o2) => {
                        const nameA = o1.name.toLocaleUpperCase()
                        const nameB = o2.name.toLocaleUpperCase()
                        const compare = nameA.localeCompare(nameB, undefined, {numeric: true, sensitivity: "base"})

                        if (compare > 0)
                            return -1
                        else if (compare < 0)
                            return 1
                        else
                            return 0
                    })

                    break
                default: break
            }

            for (let i in productList) {
                keyboard.keys.push(new Key({
                    uuid: uuidv4(),
                    action_code: 701,
                    keyboard_id: keyboard.id,
                    color: bgColor,
                    label: productList[i].label,
                    target_id: productList[i].id,
                    target_object: productList[i],
                    rank: (parseInt(i) + 1)
                }))
            }
        }

        return buildKeyboard(keyboard.zone, keyboard.grid, displayedLines, displayedColumns, maxRankDisplayed)
    }
    const buildKeyboard = (zone, grid, lines, columns, maxRank) => {
        let keys = []
        let defaultKey
        let flexDesing = "row"

        if (keyboard.type === "P" && categoryKeySelected !== null && categoryKeySelected.action_code === 501) {
            keys = keyboard.keys

            let rest = keyboard.nbColumns - (keys.length % keyboard.nbColumns)

            if (keyboard.nbColumns > rest) {
                for (let i = 0; i < rest; i++) {
                    keys.push(new Key({id: 0, uuid: uuidv4(), rank: (keys.length + (i + 1))}))
                }
            }
        }
        else {
            for (let i = 1; i <= maxRank; i++) {
                defaultKey = returnKey(i)

                if (defaultKey === null)
                    defaultKey = new Key({id: 0, uuid: uuidv4(), rank: i})

                keys.push(defaultKey)
            }
        }

        return <div className={"table " + flexDesing + " " + zone}>
            {
                keys.map((key, index) => (
                    <KeyComponent key={index} keyboard={keyboard} object={key} handleOpenAction={handleOpenAction} />
                ))
            }
        </div>
    }
    const returnKey = rank => {
        return keyboard.keys.find(_ => _.rank === rank) ?? null
    }
    const returnRank = (column, line) => {
        return keyboard.type === "M" || (keyboard.type === "P" && keyboard.sortingDirection === 'line')
            ? (line -1) * keyboard.nbColumns + column
            : (column -1) * keyboard.nbLines + line
    }

    useEffect(() => {
        window.addEventListener('resize', resize)

        return () => window.removeEventListener('resize', resize)
    }, [])
    useEffect(() => {
        setTimeout(() => { resize() }, 0)
    }, [keyboard, keyboards])

    return (
        <div id={"keyboard-" + keyboard.zone} className={"keyboard " + classname + (select ? " edit" : "")}>
            {
                ["P"].includes(keyboard.zone)
                    ? <div className={"scroller"}>
                        {fillKeyboard()}
                    </div>
                    : fillKeyboard()
            }
            <span className="highlightedBg"/>
        </div>
    )
}

export default Keyboard
