import React, {useContext, useEffect, useState} from "react";
import 'moment/locale/fr';
import SwitchItem from "../../form/input/generated/SwitchItem";
import DateSelector from "../../form/input/generated/DateSelector";
import ButtonAction from "../../form/input/generated/ButtonAction";
import DownloadIcon from "../../icons/DownloadIcon";
import Search2Icon from "../../icons/Search2Icon";
import LoaderCircle from "../../loader/LoaderCircle";
import StatisticContext from "../../../context/StatisticContext";
import '../../../css/toolsBar/analyze/ToolsBar.css';

const ToolsBar = props => {
    const { classname, loading, launchStats, launchExport, dateTypesAvailable, noCompare } = props;
    const { dateType, setDateType, dates, setDates } = useContext(StatisticContext);
    const [ buttonActions, setButtonActions ] = useState([]);
    const [ listDateType, setListDateType ] = useState([]);
    const idRandom = Math.floor(Math.random() * 999999);

    const initActions = () => {
        let actions = [
            {
                "title": "Voir",
                "icon": <Search2Icon classname={"icon"} />,
                "actionCode": 100,
                "closeWhenAction": true
            },
            {
                "title": "Exporter",
                "icon": <DownloadIcon classname={"icon"} />,
                "actionCode": 101,
                "classname": "blue",
                "closeWhenAction": true
            }
        ];

        setButtonActions(actions);
    }
    const initListDateType = types => {
        let typesTmp = [];

        if (types === undefined || types === null || types.length === 0)
            types = ['D', 'M'];

        for (let i in types) {
            switch (types[i]) {
                case 'D':
                    typesTmp.push({
                        "value": "day",
                        "label": "Jour"
                    });
                    break;
                case 'M':
                    typesTmp.push({
                        "value": "month",
                        "label": "Mois"
                    });
                    break;
                case 'Q':
                    typesTmp.push({
                        "value": "quarter",
                        "label": "Trimestre"
                    });
                    break;
                case 'Y':
                    typesTmp.push({
                        "value": "year",
                        "label": "Année"
                    });
                    break;
                default: break;
            }
        }

        setListDateType(typesTmp);
    }
    const clickAction = code => {
        switch (code) {
            case 100:
                launchStats();
                break;
            case 101:
                launchExport();
                break;
            default:
                break;
        }
    }
    const changeDateType = type => {
        setDateType(type);
    }
    const changeDateSelected = dates => {
        setDates(dates);
    }

    useEffect(() => {
        initActions();
    }, []);
    useEffect(() => {
        initListDateType(dateTypesAvailable);
    }, [dateTypesAvailable]);

    return(
        <div className={ "toolsBarStatistics form" + (classname !== undefined ? " " + classname : "") }>
            <SwitchItem attribute={"dateType"} returnType={"string"} list={listDateType} value={dateType} change={changeDateType} />
            <div className={"right"}>
                <LoaderCircle display={"loader"} hide={!loading ? "hide" : ""} strokeWidth="10" stroke="#00406F" />
                <DateSelector random={idRandom} attribute={"dateSelected"} returnType={"string"} type={dateType} value={dates} change={changeDateSelected} validCalendar={launchStats} noCompare={noCompare !== undefined ? noCompare : false} />
                <ButtonAction random={idRandom} actions={buttonActions} actionClick={clickAction} />
            </div>
        </div>
    );

}

export default ToolsBar;
