import React, { useEffect, useState } from "react";
import {Route, Switch} from "react-router-dom";
import cookie from "react-cookies";
import moment from "moment";
import PreviewBox from "../../../overbox/sale/PreviewBox";
import ToolsBar from "../../../toolsBar/analyze/ToolsBar";
import Listing from "../../../table/Listing";
import ExportStats from "../analyze/ExportStats";
import DashboardController from "../../../../stories/_statistic/Dashboard/DashboardController";
import ListingContext from "../../../../context/ListingContext";
import StatisticContext from "../../../../context/StatisticContext";
import "../../../../css/page/content/sale/Sale.css";
import "../../../../css/form/Form.css";

const Sale = props => {
    moment.locale('fr');
    const item = "sales";
    const itemClass = "sale";
    const titleWindow = "Historique des ventes";
    const placeholderSearch = "une vente, un règlement, un produit ...";
    const titleNbItems = "ventes";
    const emptyList = "Aucune vente";
    const queryParams = new URLSearchParams(window.location.search);
    let object_per_page = cookie.load(item + "_per_page");
    let object_sorting_name = cookie.load(item + "_sorting_name");
    let object_sorting_value = cookie.load(item + "_sorting_value");
    const { page } = props;
    const [ loading, setLoading ] = useState(false);
    const [ dashboard, setDashboard ] = useState(null);
    const [ exportForm, setExportForm ] = useState(false);
    const [ dateType, setDateType ] = useState("day");
    const [ dates, setDates ] = useState({ begin: moment().startOf("day").format(), end: moment().endOf("day").format() });
    const [ pageSelect, setPageSelect ] = useState(page !== null ? page : 1);
    const [ list, setList ] = useState([]);
    const [ pagination, setPagination ] = useState(null);
    const [ perPage, setPerPage ] = useState(object_per_page !== undefined ? object_per_page : 25);
    const [ sortingName, setSortingName ] = useState(object_sorting_name !== undefined ? object_sorting_name : "created_at");
    const [ sortingValue, setSortingValue ] = useState(object_sorting_value !== undefined ? object_sorting_value : "desc");
    const [ input, setInput ] = useState(queryParams.get("input") !== null ? queryParams.get("input") : "");

    const initDates = () => {
        setDates({ begin: moment().startOf(dateType).format(), end: moment().endOf(dateType).format() });
    }
    const getDashboard = () => {
        if (Object.keys(dates).length === 0)
            return;

        setLoading(true);

        const controller = new DashboardController();
        controller._callback = returnGetDashboard;
        controller.index({
            dateType: dateType,
            dates: dates
        });
    }
    const returnGetDashboard = (object, error, status) => {
        switch (status) {
            case 200:
                setDashboard(object)
                break
            default: break
        }
    }
    const launchStats = () => {
        if (loading)
            return;

        getDashboard();
    }
    const callStats = () => {
        if (dashboard === null)
            return;

        setLoading(true);

        const controller = new DashboardController();
        controller._callbackSales = returnGetSales;
        controller.get(dashboard, "sales", pageSelect, perPage, sortingName, sortingValue, input)
    }
    const returnGetSales = (list, error, pagination, status) => {
        setLoading(false)

        switch (status) {
            case 200:
                setList(list)
                setPagination(pagination !== undefined ? pagination : null)
                break
            default:
                console.log("returnGetSales", error)
                break
        }
    }
    const handleChangeInput = event => {
        const val = event.currentTarget.value

        setInput(val)
        setLoading(true)
    }
    const updatePageSelect = page => {
        setPageSelect(page)
    }
    const exportStats = () => {
        setExportForm(!exportForm);
    }

    const model = [
        {
            "class": "number",
            "sortingParam": "number",
            "title": "Numéro",
            "attributes": ["number"],
            "type": "text"
        },
        {
            "class": "seller",
            "sortingParam": "sellerName",
            "title": "Vendeur",
            "attributes": ["sellerName"],
            "type": "text"
        },
        {
            "class": "article",
            "sortingParam": "nbArticle",
            "title": "Articles",
            "attributes": ["nbArticle"],
            "type": "text"
        },
        {
            "class": "amount",
            "sortingParam": "amountInclTax",
            "title": "Montant",
            "attributes": ["amountInclTax"],
            "type": "price"
        },
        {
            "class": "source",
            "sortingParam": "source",
            "title": "Source",
            "attributes": ["source"],
            "type": "text"
        },
        {
            "class": "created_at",
            "sortingParam": "created_at",
            "title": "Date",
            "attributes": ["created_at"],
            "type": "date"
        }
    ];
    const options = [];
    const secondaryOptions = [];
    const filters = [
        /*{
            "type": "text",
            "class": "marginTop",
            "handleChange": handleChangeInput,
            "content": null,
            "value": input,
            "placeholder": "Rechercher " + placeholderSearch
        }*/
    ];

    useEffect(() => {
        document.title = "Back office - " + titleWindow;
        getDashboard();
    }, []);
    useEffect(() => {
        initDates();
    }, [dateType]);
    useEffect(() => {
        callStats()
    }, [dashboard, perPage, pageSelect, input, sortingName, sortingValue]);

    return(
        <StatisticContext.Provider value={{dateType, setDateType, dates, setDates}}>
            {
                exportForm
                && <ExportStats type={"sale"} cancel={exportStats} dates={{dates: dates}} />
            }
            <div className="main">
                <ToolsBar loading={loading} launchStats={launchStats} launchExport={exportStats} dateTypesAvailable={['D', 'M']} noCompare={true} />
            </div>
            <ListingContext.Provider value={ { page: pageSelect } }>
                <Listing
                    item={ item }
                    itemClass={ itemClass }
                    placeholderSearch={ placeholderSearch }
                    titleNbItems={ titleNbItems }
                    emptyList={ emptyList }
                    model={ model }
                    options={ options }
                    secondaryOptions={ secondaryOptions }
                    filters={ filters }
                    activeHistory={ false }
                    page={ page }
                    pageSelect={ pageSelect }
                    updatePageSelect={ updatePageSelect }
                    list={ list }
                    loading={ false }
                    pagination={ pagination }
                    checkable={ false }
                    openable={ true }
                    setPerPage={ setPerPage }
                    sortingName={ sortingName }
                    sortingValue={ sortingValue }
                    setSortingName={ setSortingName }
                    setSortingValue={ setSortingValue }
                    noMarginTopWrapList={ true }
                />
                <Switch>
                    <Route exact path={ "/" + item + "/:id" }>
                        <PreviewBox previousLink={ item } />
                    </Route>
                </Switch>
            </ListingContext.Provider>
        </StatisticContext.Provider>
    )
}

export default Sale;
