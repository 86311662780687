import React, {useContext, useEffect, useState} from "react"
import FormBuilder from "../../../class/tool/FormBuilder"
import LoaderCircle from "../../loader/LoaderCircle"
import SessionContext from "../../../context/SessionContext"
import '../../../css/form/Form.css'
import '../../../css/form/FormLogin.css'

const FormCompanies = () => {
    const { handleSwitch } = useContext(SessionContext)
    const [ values, setValues ] = useState({
        company: 0
    })
    const [ errors, setErrors ] = useState([])
    const [ saving, setSaving ] = useState(false)
    const [ globalError, setGlobalError ] = useState(null)
    const [ rows, setRows ] = useState([
        {
            label: "Groupe",
            attribute: "company",
            inputType: "select",
            returnType: "int",
            list: [],
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucun"
        }
    ])

    const buildListCompanies = () => {
        const env = JSON.parse(localStorage.getItem("env"))
        let companies = []
        let company

        for (let i in env.companies) {
            company = env.companies[i]

            companies.push({
                value: company.name,
                id: company.id
            })

            if (parseInt(i) === 0) {
                setValues(prev => ({
                    ...prev,
                    company: company.id
                }))
            }
        }

        let rowsTmp = rows.slice()
        rowsTmp[0].list = companies
        setRows(rowsTmp)
    }
    const handleChange = (attribute, returnType, val, strict = false) => {
        let value = FormBuilder.buildVal(returnType, val);
        let filtered = rows.filter(row => row.attribute === attribute && row.inputType === "select" && row.returnType === "int");
        let index = value;

        if (!strict && filtered.length > 0 && filtered[0].list.length > 0) {
            value = parseInt(filtered[0].list[index].id);

            if (filtered[0].list[index].type !== undefined && values[attribute.replace("_id", "_type")] !== undefined) {
                setValues(prev => ({
                    ...prev,
                    [attribute]: value,
                    [attribute.replace("_id", "_type")]: filtered[0].list[index].type
                }));
            }
            else {
                setValues(prev => ({
                    ...prev,
                    [attribute]: value
                }));
            }
        }
        else {
            filtered = rows.filter(row => row.attribute === attribute && row.inputType === "select" && row.returnType === "string")

            if (filtered.length > 0) {
                if (filtered[0].list.filter(_ => _.value === value && _.id !== undefined && _.id === null).length > 0)
                    value = null
            }

            setValues(prev => ({
                ...prev,
                [attribute]: value
            }))
        }
    }
    const callToSave = () => {
        setGlobalError(null)
        reinitAllEdits()
        switchCompany()
    }
    const reinitAllEdits = () => {
        setErrors([])
    }
    const switchCompany = () => {
        if (values.company === 0) {
            let errorsTmp = []
            errorsTmp["company"] = true
            setGlobalError("Veuillez sélectionner un groupe")
            setErrors(errorsTmp)
            return
        }

        setSaving(true)
        handleSwitch("company", values.company)
    }
    const handleSubmit = event => {
        if (event !== undefined)
            event.preventDefault()
        callToSave()
    }

    useEffect(() => {
        buildListCompanies()
    }, [])

    return (
        <form className="form formLogin" onSubmit={ handleSubmit }>
            {
                globalError !== null
                && <p className="globalError">{globalError}</p>
            }
            {
                rows.map((row, index) => (
                    <div key={ index } className="clearing">
                        <label className={ row.classnameLabel }>{ row.label }</label>
                        {
                            FormBuilder.buildInputByType(row, values, errors, handleChange, null, null, handleSubmit, null, null, null, index === 0)
                        }
                    </div>
                ))
            }
            <button className={"submit " + (saving ? "hide" : "")}>
                {
                    saving
                        ? "Récupération en cours..."
                        : "Choisir"
                }
                <LoaderCircle display="loader submitForm " hide={!saving ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF" />
            </button>
        </form>
    )
}

export default FormCompanies
