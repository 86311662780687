import React, { useState, useEffect, useRef } from "react"
import {Link} from "react-router-dom"
import {Redirect} from "react-router"
import LoaderCircle from "../../loader/LoaderCircle"
import BarcodeReaderController from "../../../stories/_setting/BarcodeReaders/BarcodeReaderController"
import LicenseController from "../../../stories/_account/Licenses/LicenseController"
import SerialDatas from "../../../class/tool/SerialDatas"
import FormBuilder from "../../../class/tool/FormBuilder"
import '../../../css/form/Form.css'

const FormStart = props => {
    const env = JSON.parse(localStorage.getItem("env"))
    const item = "barcodereaders"
    const controller = new BarcodeReaderController()
    const ref = useRef()
    const { object, handleIndex } = props
    const [ reload, setReload ] = useState(false)
    const [ loadingLicenses, setLoadingLicenses ] = useState(true)
    const [ values, setValues ] = useState({})
    const [ errors, setErrors ] = useState([])
    const [ saving, setSaving ] = useState(false)
    const [ globalError, setGlobalError ] = useState("")
    const [ rows, setRows ] = useState([
        {
            label: "Nom",
            attribute: "name",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Nom",
            emptyText: "Aucun"
        },
        {
            noLabel: true,
            label: "État",
            attribute: "active",
            inputType: "switch",
            returnType: "int",
            classnameInput: "marginTB"
        },
        {
            label: "Marque",
            attribute: "brand",
            inputType: "select",
            returnType: "string",
            list: [],
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucune"
        },
        {
            label: "Modèle",
            attribute: "model",
            inputType: "select",
            returnType: "string",
            list: [],
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            emptyText: "Aucun",
            hidden: true
        },
        {
            label: "Interface",
            attribute: "interfaceType",
            inputType: "select",
            returnType: "string",
            list: [],
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            emptyText: "Aucune",
            hidden: true
        },
        {
            label: "Port USB",
            attribute: "usbPort",
            inputType: "text",
            returnType: "string",
            list: [],
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            emptyText: "Aucun",
            hidden: true
        },
        {
            label: "Port IP",
            attribute: "ipPort",
            inputType: "text",
            returnType: "string",
            list: [],
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            emptyText: "Aucun",
            hidden: true
        },
        {
            label: "Adresse IP",
            attribute: "ipAddress",
            inputType: "text",
            returnType: "string",
            list: [],
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            emptyText: "Aucun",
            hidden: true
        },
        {
            label: "Port Bluetooh",
            attribute: "bltPort",
            inputType: "text",
            returnType: "string",
            list: [],
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            emptyText: "Aucun",
            hidden: true
        },
        {
            label: "Numéro de port COM",
            attribute: "serialPort",
            inputType: "text",
            returnType: "int",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Port",
            emptyText: "Aucun",
            hidden: true
        },
        {
            label: "Bits par seconde",
            attribute: "serialBaudRate",
            inputType: "select",
            returnType: "int",
            list: [],
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            emptyText: "Aucun",
            hidden: true
        },
        {
            label: "Bits de données",
            attribute: "serialDataBits",
            inputType: "select",
            returnType: "int",
            list: [],
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            emptyText: "Aucun",
            hidden: true
        },
        {
            label: "Parité",
            attribute: "serialParity",
            inputType: "select",
            returnType: "string",
            list: [],
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            emptyText: "Aucun",
            hidden: true
        },
        {
            label: "Bits d'arrêt",
            attribute: "serialStopBits",
            inputType: "select",
            returnType: "int",
            list: [],
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            emptyText: "Aucun",
            hidden: true
        },
        {
            label: "Contrôle de flux",
            attribute: "serialFlowControl",
            inputType: "select",
            returnType: "string",
            list: [],
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            emptyText: "Aucun",
            hidden: true
        },
        {
            label: "Caisse",
            attribute: "license_id",
            returnType: "int",
            inputType: "select",
            list: [],
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucune"
        }
    ])

    const getLicenses = () => {
        const controller = new LicenseController()
        controller._callback = handleGetLicenses
        controller.index(env)
    }
    const handleGetLicenses = (list, error, status) => {
        switch (status) {
            case 200:
                let rowsTmp = rows.slice()
                let licenses = [
                    {
                        value: "Choisir une caisse",
                        id: null,
                        object: {}
                    }, {
                        value: "----------",
                        id: null,
                        object: {}
                    }
                ];

                for (let item in list) {
                    licenses.push({
                        value: list[item].name + ' (' + list[item].number + ')',
                        id: list[item].id,
                        object: list[item]
                    })
                }

                rowsTmp[rowsTmp.findIndex(_ => _.attribute === "license_id")].list = licenses
                setRows(rowsTmp)
                break
            default:
                setGlobalError("Impossible de récupérer la liste des licences")
                break
        }

        setLoadingLicenses(false)
    }
    const getModelsByBrand = () => {
        let models = [
            {
                value: "Modèle",
                id: null
            },
            {
                value: "----------",
                id: null
            }
        ]
        let filtered = BarcodeReaderController._barcodeReaders.filter((m) => m.brand === values.brand)

        for(let index in filtered) {
            if (models.findIndex(_ => _.value === filtered[index].model) < 0) {
                models.push({
                    value: filtered[index].model
                });
            }
        }

        let rowsTmp = rows.slice()

        if (values.brand === null) {
            rowsTmp[rowsTmp.findIndex(_ => _.attribute === "model")].hidden = true
        }
        else {
            rowsTmp[rowsTmp.findIndex(_ => _.attribute === "model")].list = models
            rowsTmp[rowsTmp.findIndex(_ => _.attribute === "model")].hidden = false
        }

        let columns = ["interfaceType", "usbPort", "serialPort", "serialBaudRate", "serialDataBits", "serialParity", "serialStopBits", "serialFlowControl", "ipPort", "ipAddress", "bltPort"]
        for (let i = 0; i < columns.length; i++)
            rowsTmp[rowsTmp.findIndex(_ => _.attribute === columns[i])].hidden = true

        setRows(rowsTmp)
    }
    const getInterfaceTypesByModel = () => {
        let interfaceTypes = [
            {
                value: "Interface",
                id: null
            }, {
                value: "----------",
                id: null
            }
        ]
        let peripheral = BarcodeReaderController._barcodeReaders.find((m) => m.model === values.model)

        if (peripheral !== undefined) {
            let interfaces = peripheral.interfaceTypes.split(",");

            for(let index in interfaces) {
                interfaceTypes.push({
                    value: interfaces[index]
                });
            }
        }

        let rowsTmp = rows.slice()

        if (values.model === null) {
            rowsTmp[rowsTmp.findIndex(_ => _.attribute === "interfaceType")].hidden = true
        }
        else {
            rowsTmp[rowsTmp.findIndex(_ => _.attribute === "interfaceType")].list = interfaceTypes
            rowsTmp[rowsTmp.findIndex(_ => _.attribute === "interfaceType")].hidden = false
        }

        let columns = ["usbPort", "serialPort", "serialBaudRate", "serialDataBits", "serialParity", "serialStopBits", "serialFlowControl", "ipPort", "ipAddress", "bltPort"]
        for (let i = 0; i < columns.length; i++)
            rowsTmp[rowsTmp.findIndex(_ => _.attribute === columns[i])].hidden = true

        setRows(rowsTmp)
    }
    const initRows = () => {
        let brands = [
            {
                value: "Marque",
                id: null
            },
            {
                value: "----------",
                id: null
            }
        ];
        let peripheral

        for(let index in BarcodeReaderController._barcodeReaders) {
            peripheral = BarcodeReaderController._barcodeReaders[index]

            if (brands.findIndex(_ => _.value === peripheral.brand) < 0)
                brands.push({ value: peripheral.brand });
        }

        let rowsTmp = rows.slice();

        rowsTmp[rowsTmp.findIndex(_ => _.attribute === "brand")].list = brands;
        rowsTmp[rowsTmp.findIndex(_ => _.attribute === "serialBaudRate")].list = SerialDatas.listBaudRates()
        rowsTmp[rowsTmp.findIndex(_ => _.attribute === "serialDataBits")].list = SerialDatas.listDataBits()
        rowsTmp[rowsTmp.findIndex(_ => _.attribute === "serialParity")].list = SerialDatas.listParities()
        rowsTmp[rowsTmp.findIndex(_ => _.attribute === "serialStopBits")].list = SerialDatas.listStopBits()
        rowsTmp[rowsTmp.findIndex(_ => _.attribute === "serialFlowControl")].list = SerialDatas.listFlowControls()

        setRows(rowsTmp);
    }
    const formInterfaceType = () => {
        let rowsTmp = rows.slice()
        let display = BarcodeReaderController._barcodeReaders.filter(_ => _.model === values.model)

        let columns = ["usbPort", "serialPort", "serialBaudRate", "serialDataBits", "serialParity", "serialStopBits", "serialFlowControl", "ipPort", "ipAddress", "bltPort"]
        for (let i = 0; i < columns.length; i++)
            rowsTmp[rowsTmp.findIndex(_ => _.attribute === columns[i])].hidden = true

        if (values.interfaceType !== null) {
            switch (values.interfaceType.toLowerCase()) {
                case "usb":
                    rowsTmp[rows.findIndex(_ => _.attribute === "usbPort")].hidden = false

                    if (display.length > 0 && (values.usbPort === null || values.usbPort === "") && display[0].defaultUsbPort !== undefined) {
                        setValues(prev => ({
                            ...prev,
                            ["usbPort"]: display[0].defaultUsbPort
                        }))
                    }

                    break;
                case "serial":
                    rowsTmp[rows.findIndex(_ => _.attribute === "serialPort")].hidden = false
                    rowsTmp[rows.findIndex(_ => _.attribute === "serialBaudRate")].hidden = false
                    rowsTmp[rows.findIndex(_ => _.attribute === "serialDataBits")].hidden = false
                    rowsTmp[rows.findIndex(_ => _.attribute === "serialParity")].hidden = false
                    rowsTmp[rows.findIndex(_ => _.attribute === "serialStopBits")].hidden = false
                    rowsTmp[rows.findIndex(_ => _.attribute === "serialFlowControl")].hidden = false
                    break;
                case "ip":
                    rowsTmp[rows.findIndex(_ => _.attribute === "ipPort")].hidden = false
                    rowsTmp[rows.findIndex(_ => _.attribute === "ipAddress")].hidden = false
                    break;
                case "bluetooth":
                    rowsTmp[rows.findIndex(_ => _.attribute === "bltPort")].hidden = false

                    if (display.length > 0 && (values.bltPort === null || values.bltPort === "") && display[0].defaultBltPort !== undefined) {
                        setValues(prev => ({
                            ...prev,
                            ["bltPort"]: display[0].defaultBltPort
                        }))
                    }

                    break;
                default: break;
            }
        }

        setRows(rowsTmp);
    }
    const initValues = () => {
        controller.setFormValues(object, setValues, true)
    }
    const handleChange = (attribute, returnType, val, strict = false) => {
        let value = FormBuilder.buildVal(returnType, val)
        let filtered = rows.filter(row => row.attribute === attribute && row.inputType === "select" && row.returnType === "int")
        let index = value

        if (!strict && filtered.length > 0 && filtered[0].list.length > 0) {
            value = parseInt(filtered[0].list[index].id)

            if (filtered[0].list[index].type !== undefined && values[attribute.replace("_id", "_type")] !== undefined) {
                setValues(prev => ({
                    ...prev,
                    [attribute]: value,
                    [attribute.replace("_id", "_type")]: filtered[0].list[index].type
                }))
            }
            else {
                setValues(prev => ({
                    ...prev,
                    [attribute]: value
                }))
            }
        }
        else {
            filtered = rows.filter(row => row.attribute === attribute && row.inputType === "select" && row.returnType === "string")

            if (filtered.length > 0) {
                if (filtered[0].list.filter(_ => _.value === value && _.id !== undefined && _.id === null).length > 0)
                    value = null
            }

            setValues(prev => ({
                ...prev,
                [attribute]: value
            }))
        }
    }
    const reinitAllEdits = () => {
        setErrors([]);
    }
    const returnUpdates = () => {
        return controller.returnUpdatesFromCompare(object, values);
    }
    const check422Errors = errorDatas => {
        setGlobalError("Certaines données sont invalides");

        if(errorDatas !== undefined) {
            let keys = Object.keys(errorDatas);
            let fields = ["reference"];

            for(let item in fields)
                if(keys.includes(fields[item]))
                    defineErrors(fields[item], false);
        }
    }
    const defineErrors = (type, empty) => {
        let errorsTmp = errors.slice();

        switch (type) {
            case "name":
                if(empty) errorsTmp["name"] = "Vous devez saisir un nom";
                else errorsTmp["name"] = "Ce nom n'est pas valide";
                break;
            default: break;
        }

        setErrors(errorsTmp);
    }
    const handleReturnSave = (response, error) => {
        if(error) {
            if(error.response !== undefined) {
                if(error.response.status === 422)
                    check422Errors(error.response.data);
            }
            else
                setGlobalError("Une erreur s'est produite lors de l'enregistrement");
        }
        else {
            setReload(true);
            handleIndex();
        }

        setSaving(false);
    }
    const save = () => {
        const datas = returnUpdates()

        if(Object.keys(datas).length === 0) return

        setSaving(true)

        controller._callback = handleReturnSave
        controller.post(datas)
    }
    const callToSave = () => {
        setGlobalError("");
        reinitAllEdits();
        save();
    }
    const handleSubmit = event => {
        if (event !== undefined)
            event.preventDefault();
        callToSave();
    }

    useEffect(() => {
        getLicenses();
        initValues();
    }, [])
    useEffect(() => {
        if (!loadingLicenses)
            initRows()
    }, [loadingLicenses])
    useEffect(() => {
        if (ref.current !== undefined) {
            if (ref.current.brand !== undefined && ref.current.brand !== values.brand)
                getModelsByBrand()
            else if (ref.current.model !== undefined && ref.current.model !== values.model)
                getInterfaceTypesByModel()
            else if (ref.current.interfaceType !== undefined && ref.current.interfaceType !== values.interfaceType)
                formInterfaceType()
        }

        ref.current = values
    }, [values])
    useEffect(() => {
        if ((values.target !== undefined && values.target === null) && rows[rows.findIndex(_ => _.attribute === "target")].list.length === 3) // if only one choice
            handleChange("target", "string", rows[rows.findIndex(_ => _.attribute === "target")].list[rows[rows.findIndex(_ => _.attribute === "target")].list.length - 1].value)

        if ((values.brand !== undefined && values.brand === null) && rows[rows.findIndex(_ => _.attribute === "brand")].length === 3) // if only one choice
            handleChange("brand", "string", rows[rows.findIndex(_ => _.attribute === "brand")].list[rows[rows.findIndex(_ => _.attribute === "brand")].list.length - 1].value)

        if ((values.interfaceType !== undefined && values.interfaceType === null) && rows[rows.findIndex(_ => _.attribute === "interfaceType")].length === 3) // if only one choice
            handleChange("interfaceType", "string", rows[rows.findIndex(_ => _.attribute === "interfaceType")].list[rows[rows.findIndex(_ => _.attribute === "interfaceType")].list.length - 1].value)
    }, [rows])

    return (
        <div className="clearing">
            {
                reload && <Redirect to={ "/" + item } />
            }
            {
                loadingLicenses
                    ? <LoaderCircle display="loader logWait" hide="" strokeWidth="5"/>
                    : <form className="form" onSubmit={handleSubmit}>
                        {
                            globalError !== "" && <p className="globalError">{globalError}</p>
                        }
                        {
                            rows.map((row, index) => (
                                <div key={ index } className="clearing">
                                    {
                                        (row.hidden === undefined || !row.hidden)
                                        && <div className="clearing">
                                            {
                                                row.noLabel === undefined
                                                && <label className={ row.classnameLabel }>{ row.label }</label>
                                            }
                                            {
                                                FormBuilder.buildInputByType(row, values, errors, handleChange, null, null, handleSubmit, null, null, null, index === 0)
                                            }
                                        </div>
                                    }
                                </div>
                            ))
                        }
                        <button className={"submit " + (saving ? "hide" : "")}>
                            {
                                saving
                                    ? "Enregistrement..."
                                    : "Enregistrer"
                            }
                            <LoaderCircle display="loader submitForm " hide={!saving ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF" />
                        </button>
                        <Link to={ "/" + item }>
                            <button className="cancel align">Fermer</button>
                        </Link>
                        <div className="clearing" />
                    </form>
            }
        </div>
    )
}

export default FormStart
