import Shareable from "../../../class/abstract/Shareable"
import Price from "../Prices/Price"
import Vat from "../Vats/Vat"
import PriceVariation from "../PriceVariations/PriceVariation"
import Barcode from "../../_barcode/Barcode"
import Catalog from "../Catalogs/Catalog"

class Product extends Shareable {
    name = null
    label = null
    color = null
    reference = null
    referenceProvider = null
    store_id = null
    provider_id = null
    provider_name = null
    brand_id = null
    brand_name = null
    category_id = null
    category_name = null
    category_type = null
    catalog_id = null
    catalog = null
    catalog_name = null
    description = null
    nullStockTrigger = null
    packContent_id = null
    parentItem_id = null
    printDescription = null
    quantity = null
    soldOut = null
    stockManagement = null
    stockThresholdTrigger = null
    unit = null
    url = null
    urlSync = null
    variablePrice = null
    price = null
    vat = null
    vat_rate_id = null
    last_histo_author = null
    price_variations = null
    prices = null
    vats = null
    barcodes = null

    constructor(datas = {}) {
        super(datas);
        this.setAttributes(datas);
    }

    setAttributes(datas) {
        let keys = Object.keys(this);
        let key = "";
        let item;

        for(let i in keys) {
            key = keys[i];

            if (datas[key] !== undefined) {
                if(key === "prices") {
                    this.prices = [];

                    for (item in datas[key])
                        this.prices.push(new Price(datas[key][item]));
                }
                else if(key === "vats") {
                    this.vats = [];

                    for (item in datas[key])
                        this.vats.push(new Vat(datas[key][item]));
                }
                else if(key === "price_variations") {
                    this.price_variations = [];

                    for (item in datas[key])
                        this.price_variations.push(new PriceVariation(datas[key][item]));
                }
                else if(key === "category_type") {
                    if (datas[key].toLowerCase().includes("subcategory")) Reflect.set(this, key, "subcategories");
                    else Reflect.set(this, key, "categories");
                }
                else if(key === "barcodes") {
                    this.barcodes = [];

                    for (item in datas[key])
                        this.barcodes.push(new Barcode(datas[key][item]));
                }
                else if(key === "last_histo_author")
                    Reflect.set(this, key, datas[key] !== null ? datas[key].toLowerCase() : "");
                else
                    Reflect.set(this, key, datas[key]);
            }
        }

        if (datas.catalog !== undefined && datas.catalog !== null) {
            this.catalog = new Catalog(datas.catalog);
            this.catalog_name = this.catalog.name;
        }
        else if (datas.catalog_id !== null) {
            const catalogs = JSON.parse(sessionStorage.getItem("catalogs"));
            this.catalog = catalogs.find(_ => _.id === datas.catalog_id);
        }

        if (this.catalog !== undefined && this.catalog !== null)
            this.catalog_name = this.catalog.name;
    }

    getDefaultPrice() {
        for (let item in this.prices) {
            if (this.prices[item].priceList.default !== undefined && this.prices[item].priceList.main === 1)
                return (typeof this.prices[item].value === "string" ? parseFloat(this.prices[item].value.replace(",", ".")) : this.prices[item].value);
        }

        return null;
    }
    getDefaultVatName() {
        for (let item in this.vats) {
            if (this.vats[item].priceList.default !== undefined && this.vats[item].priceList.main === 1)
                return this.vats[item].name;
        }

        return "-";
    }
    getDefaultVat() {
        for (let item in this.vats) {
            if (this.vats[item].priceList.default !== undefined && this.vats[item].priceList.main === 1)
                return this.vats[item].value;
        }

        return null;
    }
}

export default Product
