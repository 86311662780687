import Shareable from "../../../class/abstract/Shareable";
import SubCategory from "../SubCategories/SubCategory";
import VatRule from "../VatRules/VatRule";
import Vat from "../Vats/Vat";
import PriceVariationRule from "../PriceVariationRules/PriceVariationRule";
import Catalog from "../Catalogs/Catalog";

class Category extends Shareable {
    name = null;
    label = null;
    color = null;
    reference = null;
    referenceProvider = null;
    sharedUuid = null;
    vat_rate_id = null;
    brand_id = null;
    groupment_id = null;
    parentItem_id = null;
    catalog_id = null;
    catalog = null;
    catalog_name = null;
    subCategories = [];
    priceRules = [];
    vatRules = [];
    price = null;
    vat = null;
    last_histo_author = null;

    constructor(datas = {}) {
        super(datas);
        this.setAttributes(datas);
    }

    setAttributes(datas) {
        let keys = Object.keys(this);
        let key = "";

        for(let i in keys) {
            key = keys[i];

            if (datas[key] !== undefined) {
                // except keys
                let exceptKeys = [
                    "catalog",
                    "subCategories",
                    "vatRules",
                    "priceRules",
                    "vat"
                ];

                if (!exceptKeys.includes(key)) {
                    if(key === "last_histo_author")
                        Reflect.set(this, key, datas[key] !== null ? datas[key].toLowerCase() : "");
                    else
                        Reflect.set(this, key, datas[key]);
                }
            }
        }

        if (datas.catalog !== undefined && datas.catalog !== null) {
            this.catalog = new Catalog(datas.catalog);
            this.catalog_name = this.catalog.name;
        }
        else if (datas.catalog_id !== null) {
            const catalogs = JSON.parse(sessionStorage.getItem("catalogs"));
            this.catalog = catalogs.find(_ => _.id === datas.catalog_id);
        }

        if (this.catalog !== undefined && this.catalog !== null)
            this.catalog_name = this.catalog.name;

        if (datas.subCategories !== undefined && datas.subCategories.length > 0)
            for (let i in datas.subCategories)
                this.subCategories.push(new SubCategory(datas.subCategories[i]));

        if (datas.vatRules !== undefined && datas.vatRules.length > 0)
            for (let i in datas.vatRules)
                this.vatRules.push(new VatRule(datas.vatRules[i]));

        if (datas.priceRules !== undefined && datas.priceRules.length > 0)
            for (let i in datas.priceRules)
                this.priceRules.push(new PriceVariationRule(datas.priceRules[i]));

        if (datas.vat !== undefined && datas.vat !== null) {
            this.vat = new Vat(datas.vat);
            this.vat_rate_id = datas.vat.id;
        }
    }
}

export default Category;
