import React, {useEffect, useState} from "react"
import FormBuilder from "../../../class/tool/FormBuilder"
import CatalogController from "../../../stories/_catalog/Catalogs/CatalogController";

const CatalogFormSheet = props => {
    const env = JSON.parse(localStorage.getItem("env"))
    const storeSettings = JSON.parse(localStorage.getItem("storeSettings"))
    const { object, values, errors, updated, handleChange, handleSave } = props
    const [ loadingStores, setLoadingStores ] = useState(true)
    const [ stores, setStores ] = useState([])
    const [ edit, setEdit ] = useState("")
    const [ rows, setRows ] = useState([
        {
            label: "Nom",
            attribute: "name",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Nom",
            emptyText: "Aucun",
            edit: (object.sharedUuid === null || (object.sharedUuid !== null && storeSettings !== null && storeSettings.allowedProductCreation === 1))
        },
        {
            label: "Couleur",
            attribute: "color",
            inputType: "color",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucune",
            edit: (object.sharedUuid === null || (object.sharedUuid !== null && storeSettings !== null && storeSettings.allowedProductCreation === 1))
        }
    ])

    const initStores = () => {
        let storesTmp = []

        for (let i in env.stores) {
            storesTmp.push({
                type: "text",
                value: env.stores[i].shortName
            })
        }

        setStores(storesTmp)
        setLoadingStores(false)
    }
    const buildRow = row => {
        if (edit === row.attribute || ["tag"].includes(row.inputType) || (["color"].includes(row.inputType) && row.edit === undefined))
            return FormBuilder.buildInputByType(row, values, errors, change, reinitEdit)
        else
            return FormBuilder.buildNoInputByType(row, values, handleEdit)
    }
    const initRows = () => {
        let rowsTmp = rows.slice()

        if (env.type === "company") {
            rowsTmp.push({
                label: "Boutiques",
                attribute: "stores",
                inputType: "tag",
                returnType: "array",
                classnameLabel: "label",
                classnameInput: "",
                classnameNoInput: "",
                placeholder: "",
                list: stores,
                dictionary: null,
                titleButton: "Modifier",
                loading: loadingStores,
                removeRules: ["admin", "skytech"]
            })
        }

        setRows(rowsTmp)
    }
    const refreshRows = () => {
        let rowsTmp = rows.slice()
        let index = rowsTmp.findIndex(_ => _.attribute === "stores")

        if (index < 0) return

        rowsTmp[rowsTmp.findIndex(_ => _.attribute === "stores")].list = stores
        rowsTmp[rowsTmp.findIndex(_ => _.attribute === "stores")].loading = loadingStores
        setRows(rowsTmp)
    }
    const handleEdit = attribute => {
        setEdit(attribute)
    }
    const change = (attribute, returnType, val, strict = false) => {
        handleChange(rows, attribute, returnType, val, strict)
    }
    const reinitEdit = () => {
        setEdit("")
    }
    const handleSubmit = event => {
        if (event !== undefined)
            event.preventDefault()
        handleSave()
    }

    useEffect(() => {
        initRows()
        initStores()
    }, [])
    useEffect(() => {
        refreshRows()
    }, [stores, loadingStores])

    return (
        <form
            className={ "form fullHeight" + (updated ? " withSavingBar" : "") }
            onSubmit={ handleSubmit } >
            {
                rows.map((row, index) => (
                    <div key={ index } className="clearing">
                        <label className={ row.classnameLabel }>{ row.label }</label>
                        {
                            buildRow(row)
                        }
                    </div>
                ))
            }
        </form>
    )
}

export default CatalogFormSheet
