class StringTools {
    static capitalizeFirstLetter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1)
    }
    static removeAccents(str) {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    }
    static removeSpecialChars(str) {
        return str.replace(/[^a-zA-Z ]/g, '')
    }
    static number_format(number, decimals, decPoint, thousandsSep) {
        number = (number + '').replace(/[^0-9+\-Ee.]/g, '')
        const n = !isFinite(+number) ? 0 : +number
        const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
        const sep = typeof thousandsSep === 'undefined' ? ',' : thousandsSep
        const dec = typeof decPoint === 'undefined' ? '.' : decPoint
        let s = ''

        const toFixedFix = function (n, prec) {
            if (('' + n).indexOf('e') === -1) {
                return +(Math.round(n + 'e+' + prec) + 'e-' + prec)
            } else {
                const arr = ('' + n).split('e')
                let sig = ''
                if (+arr[1] + prec > 0) {
                    sig = '+'
                }
                return (+(Math.round(+arr[0] + 'e' + sig + (+arr[1] + prec)) + 'e-' + prec)).toFixed(prec)
            }
        }

        // @todo: for IE parseFloat(0.55).toFixed(0) = 0;
        s = (prec ? toFixedFix(n, prec).toString() : '' + Math.round(n)).split('.')
        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
        }
        if ((s[1] || '').length < prec) {
            s[1] = s[1] || ''
            s[1] += new Array(prec - s[1].length + 1).join('0')
        }

        return s.join(dec)
    }
    static getFirstChars(str) {
        if (str === undefined || str === null)
            return "";

        let strReturn = "";
        let explode = str.toLocaleUpperCase().split(' ');

        for (let i in explode) {
            if (explode[i].length === 0)
                continue;

            strReturn += explode[i].charAt(0);
        }

        return strReturn;
    }
}

export default StringTools
