import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import LoaderCircle from "../../loader/LoaderCircle";
import PaymentMethodController from "../../../stories/_setting/PaymentMethods/PaymentMethodController";
import PredefinedPaymentMethodController from "../../../stories/_setting/PredefinedPaymentMethods/PredefinedPaymentMethodController";
import '../../../css/form/Form.css';
import FormBuilder from "../../../class/tool/FormBuilder";

const FormStart = props => {
    const item = "paymentmethods"
    const controller = new PaymentMethodController()

    const { object, handleIndex } = props;
    const [ reload, setReload ] = useState(false);
    const [ loadingPredefinedPaymentMethods, setLoadingPredefinedPaymentMethods ] = useState(true);
    const [ values, setValues ] = useState({});
    const [ errors, setErrors ] = useState([]);
    const [ saving, setSaving ] = useState(false);
    const [ globalError, setGlobalError ] = useState("");
    const [ rows, setRows ] = useState([
        {
            label: "Type prédéfini",
            attribute: "predefined_payment_method_id",
            inputType: "select",
            returnType: "int",
            list: [],
            dictionary: null,
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "",
            emptyText: "Aucun"
        },
        {
            label: "Nom",
            attribute: "name",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Nom",
            emptyText: "Aucun"
        },
        {
            label: "Réference",
            attribute: "reference",
            inputType: "text",
            returnType: "string",
            classnameLabel: "label",
            classnameInput: "",
            classnameNoInput: "",
            placeholder: "Réference",
            emptyText: "Aucune"
        },
        {
            noLabel: true,
            label: "Ouvrir le tiroir caisse",
            attribute: "openCashRegister",
            inputType: "switch",
            returnType: "int",
            classnameInput: ""
        },
        {
            noLabel: true,
            label: "Générer un avoir",
            attribute: "assetManagement",
            inputType: "switch",
            returnType: "int",
            classnameInput: ""
        }
    ]);

    const handleGetPredefinedPaymentMethods = (list, error) => {
        if (error) {
            alert("Impossible de récupérer les catégories");
        }

        let rowsTmp = rows.slice();
        let predefinedPaymentMethods = [
            {
                value: "Aucun",
                id: null
            }, {
                value: "----------",
                id: null
            }
        ];

        for (let item in list) {
            if (list[item].hidden === 1) continue;

            predefinedPaymentMethods.push({
                value: list[item].name,
                id: list[item].id,
                object: list[item]
            });
        }

        rowsTmp[0].list = predefinedPaymentMethods;
        setRows(rowsTmp);
        setLoadingPredefinedPaymentMethods(false);
    }
    const getPredefinedPaymentMethods = () => {
        const controller = new PredefinedPaymentMethodController();
        controller._callback = handleGetPredefinedPaymentMethods;
        controller.index();
    }
    const initValues = () => {
        controller.setFormValues(object, setValues)
    }
    const handleChange = (attribute, returnType, val, strict = false) => {
        let value = FormBuilder.buildVal(returnType, val);
        let filtered = rows.filter(row => row.attribute === attribute && row.inputType === "select" && row.returnType === "int");
        let index = value;

        if (!strict && filtered.length > 0 && filtered[0].list.length > 0) {
            value = parseInt(filtered[0].list[index].id);

            if (filtered[0].list[index].type !== undefined && values[attribute.replace("_id", "_type")] !== undefined) {
                setValues(prev => ({
                    ...prev,
                    [attribute]: value,
                    [attribute.replace("_id", "_type")]: filtered[0].list[index].type
                }));
            }
            else {
                if (attribute === "predefined_payment_method_id") {
                    let predefined = rows[0].list.filter(_ => _.id === value)
                    let name = predefined.length > 0 ? predefined[0].value : ""

                    setValues(prev => ({
                        ...prev,
                        [attribute]: value,
                        name: name
                    }))
                }
                else {
                    setValues(prev => ({
                        ...prev,
                        [attribute]: value
                    }))
                }
            }
        }
        else {
            filtered = rows.filter(row => row.attribute === attribute && row.inputType === "select" && row.returnType === "string")

            if (filtered.length > 0) {
                if (filtered[0].list.filter(_ => _.value === value && _.id !== undefined && _.id === null).length > 0)
                    value = null
            }

            if (attribute === "predefined_payment_method_id") {
                let predefined = rows[0].list.filter(_ => _.id === value)

                if (predefined.length > 0) {
                    setValues(prev => ({
                        ...prev,
                        [attribute]: value,
                        name: predefined[0].value
                    }))
                }
            }
            else {
                setValues(prev => ({
                    ...prev,
                    [attribute]: value
                }))
            }
        }
    }
    const callToSave = () => {
        setGlobalError("");
        reinitAllEdits();
        save();
    }
    const reinitAllEdits = () => {
        setErrors([]);
    }
    const returnUpdates = () => {
        return controller.returnUpdatesFromCompare(object, values);
    }
    const check422Errors = errorDatas => {
        setGlobalError("Certaines données sont invalides");

        if(errorDatas !== undefined) {
            var keys = Object.keys(errorDatas);
            var fields = ["reference"];

            for(var item in fields)
                if(keys.includes(fields[item]))
                    defineErrors(fields[item], false);
        }
    }
    const defineErrors = (type, empty) => {
        let errorsTmp = errors.slice();

        switch (type) {
            case "name":
                if(empty) errorsTmp["name"] = "Vous devez saisir un nom";
                else errorsTmp["name"] = "Ce nom n'est pas valide";
                break;
            default: break;
        }

        setErrors(errorsTmp);
    }
    const handleReturnSave = (response, error, status) => {
        setSaving(false);

        switch (status) {
            case 201:
                setReload(true);
                handleIndex();
                break;
            case 422:
                check422Errors(error.response.data);
                break;
            default:
                setGlobalError("Une erreur s'est produite lors de l'enregistrement");
                break;
        }
    }
    const save = () => {
        const datas = returnUpdates();

        if(Object.keys(datas).length === 0) return;

        setSaving(true);

        controller._callback = handleReturnSave;
        controller.post(datas);
    }
    const handleSubmit = event => {
        if (event !== undefined)
            event.preventDefault();
        callToSave();
    }

    useEffect(() => {
        getPredefinedPaymentMethods();
        initValues();
    }, []);

    return (
        <div className="clearing">
            {
                reload && <Redirect to={ "/" + item } />
            }
            {
                loadingPredefinedPaymentMethods
                    ? <LoaderCircle display="loader logWait" hide="" strokeWidth="5"/>
                    : <form className="form" onSubmit={handleSubmit}>
                        {
                            globalError !== "" && <p className="globalError">{globalError}</p>
                        }
                        {
                            rows.map((row, index) => (
                                <div key={ index } className="clearing">
                                    {
                                        (row.hidden === undefined || !row.hidden)
                                        && <div className="clearing">
                                            {
                                                row.noLabel === undefined
                                                && <label className={ row.classnameLabel }>{ row.label }</label>
                                            }
                                            {
                                                FormBuilder.buildInputByType(row, values, errors, handleChange, null, null, handleSubmit, null, null, null, index === 0)
                                            }
                                        </div>
                                    }
                                </div>
                            ))
                        }
                        <button className={"submit " + (saving ? "hide" : "")}>
                            {
                                saving
                                    ? "Enregistrement..."
                                    : "Enregistrer"
                            }
                            <LoaderCircle display="loader submitForm " hide={!saving ? "hide" : ""} strokeWidth="8" stroke="#FFFFFF" />
                        </button>
                        <Link to={ "/" + item }>
                            <button className="cancel align">Fermer</button>
                        </Link>
                        <div className="clearing" />
                    </form>
            }
        </div>
    )
}

export default FormStart;
